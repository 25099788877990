var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __commonJS = (cb, mod) => function __require() {
  return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __reExport = (target, mod, secondTarget) => (__copyProps(target, mod, "default"), secondTarget && __copyProps(secondTarget, mod, "default"));
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// ../utils/handlerConfig.js
var require_handlerConfig = __commonJS({
  "../utils/handlerConfig.js"(exports2, module2) {
    var originalComponentCf = process.env.COMPONENTS;
    var readApplicationConfig = (config, componentDefinitions = originalComponentCf) => {
      const application = {
        pages: {},
        config: {},
        components: {}
      };
      const { config: configValue, environmentConfig = {} } = config;
      if (configValue["environment"]) {
        let configSetting = configValue;
        if (environmentConfig[configValue["environment"]]) {
          configSetting = Object.assign(configSetting, environmentConfig[configValue["environment"]]);
          Object.assign(config, structuredClone({ config: { ...configSetting } }));
        }
      }
      Object.assign(application, structuredClone(config));
      const { pages = {}, components: applicationComponents = {} } = application;
      let ctr = 0;
      const allComponentTypesMap = {};
      function createReactElements(node) {
        if (!node)
          return null;
        ctr += 1;
        let resolvedComponent = node;
        let id = "";
        const isRef = node.type === "ref";
        if (isRef) {
          const { ref } = node;
          if (ref && applicationComponents[ref]) {
            resolvedComponent = applicationComponents[ref];
            id = ref;
          } else if (!applicationComponents[ref]) {
            console.error(`Could not find ref: ${ref}`);
          }
        }
        const { type = "" } = resolvedComponent;
        if (!resolvedComponent.key) {
          resolvedComponent.key = type.length ? `${type}-${ctr}` : `fragment-${ctr}`;
        }
        if (type) {
          if (type !== "Fragment") {
            allComponentTypesMap[type] = true;
          }
          if (id) {
            resolvedComponent.id = id;
          }
        }
        let resolvedType = !type ? "Fragment" : type;
        const { type: _, children = [], ...props } = resolvedComponent;
        const componentDefinition = componentDefinitions[type];
        const childElements = (children || []).map(createReactElements);
        const processProps = (props2, propDefinition) => {
          Object.keys(propDefinition).forEach((key) => {
            if (props2[key] && propDefinition[key].type === "array" && Array.isArray(props2[key])) {
              const propItems = propDefinition[key].items || {};
              if (propItems && propItems.type === "slot") {
                props2[key] = props2[key].map((arraySlot) => createReactElements(arraySlot));
              } else {
                props2[key].forEach((item, index) => {
                  if (typeof item === "object") {
                    props2[key][index] = processProps(item, propItems.properties || {});
                  }
                });
              }
            } else if (props2[key] && propDefinition[key].type === "object" && typeof props2[key] === "object") {
              props2[key] = processProps(props2[key], propDefinition[key].properties || {});
            } else if (props2[key] && propDefinition[key].type === "slot" && Array.isArray(props2[key]?.children)) {
              props2[key].children = props2[key].children.map((child) => createReactElements(child));
            }
          });
          return props2;
        };
        let newProps = {};
        if (props) {
          const { inputProps = {} } = componentDefinition || {};
          newProps = structuredClone(props);
          if (inputProps && Object.keys(inputProps).length) {
            processProps(newProps, inputProps);
          }
        }
        const resolvedConfig = resolvedType !== "Fragment" ? Object.assign({}, newProps) : newProps;
        return {
          type: resolvedType,
          props: {
            ...resolvedConfig,
            ...isRef ? { refComponent: node.ref } : {}
          },
          children: childElements,
          __isComponent: true
        };
      }
      Object.keys(pages).forEach((page) => {
        pages[page].elements = pages[page].components.map((rootComponent) => {
          return createReactElements(rootComponent);
        });
      });
      Object.keys(applicationComponents).forEach((name) => {
        const component = applicationComponents[name];
        applicationComponents[name] = createReactElements(component);
      });
      return Object.assign(application, {
        allComponentTypes: Object.keys(allComponentTypesMap)
      });
    };
    var isValidValue = (value) => value !== void 0 && value !== null;
    module2.exports = {
      readApplicationConfig,
      isValidValue
    };
  }
});

// ../utils/index.js
var utils_exports = {};
__export(utils_exports, {
  ACTION_TYPES: () => ACTION_TYPES,
  AppContext: () => AppContext,
  DefaultFailback: () => DefaultFailback,
  ErrorDrawer: () => ErrorDrawer,
  RenderHiddenDiv: () => RenderHiddenDiv,
  assignRef: () => assignRef,
  assignRefToProps: () => assignRefToProps,
  base64ToFile: () => base64ToFile2,
  components: () => components_exports,
  convertToKebabCase: () => convertToKebabCase,
  dateUtils: () => date_exports,
  downloadFile: () => downloadFile,
  fileToBase64: () => fileToBase64,
  findChildren: () => findChildren,
  flattenChildren: () => flattenChildren,
  generateObject: () => generateObject,
  getAtPath: () => getAtPath,
  getCache: () => getCache,
  getDescriptionTooltip: () => getDescriptionTooltip,
  getElementById: () => getElementById,
  getFileName: () => getFileName2,
  getTokenFromUrl: () => getTokenFromUrl,
  getUser: () => getUser,
  handleApiResponse: () => handleApiResponse,
  handleCallApi: () => handleCallApi,
  hash256: () => hash256,
  initAuth0Sdk: () => initAuth0Sdk,
  initRootElement: () => initRootElement,
  injectParseFormulaIntoRules: () => injectParseFormulaIntoRules,
  injectStyle: () => injectStyle,
  isAuthenticated: () => isAuthenticated,
  isCache: () => isCache,
  isExpression: () => isExpression,
  isNilOrEmpty: () => isNilOrEmpty,
  joinFieldPaths: () => joinFieldPaths,
  jsonParseSafe: () => jsonParseSafe,
  jsonStringifySafe: () => jsonStringifySafe,
  loadRemoteComponent: () => loadRemoteComponent_exports,
  loadStyles: () => loadStyles,
  parseData: () => parseData,
  processCustomRules: () => processCustomRules,
  safeGet: () => safeGet,
  safeGetBoolean: () => safeGetBoolean,
  safeGetFunction: () => safeGetFunction,
  safeGetString: () => safeGetString,
  setAtPath: () => setAtPath,
  setCache: () => setCache,
  storage: () => storage,
  stringUtils: () => string_exports,
  switchRefToForward: () => switchRefToForward,
  transformData: () => transformData,
  tryParseExpression: () => tryParseExpression,
  tryParseFormulaToArray: () => tryParseFormulaToArray,
  tryParseFormulaToInteger: () => tryParseFormulaToInteger,
  tryParseFormulaToNumber: () => tryParseFormulaToNumber,
  tryParseFormulaToString: () => tryParseFormulaToString,
  tryParseFormulaToType: () => tryParseFormulaToType,
  useAppContext: () => useAppContext,
  useInterval: () => useInterval,
  useLocalStorage: () => useLocalStorage,
  useMount: () => useMount,
  useResizeObserver: () => useResizeObserver,
  useResponsiveStyle: () => useResponsiveStyle,
  verifyToken: () => verifyToken2,
  withErrorBoundary: () => withErrorBoundary
});
module.exports = __toCommonJS(utils_exports);

// ../utils/cache.js
var import_get = __toESM(require("lodash/get"));
var import_set = __toESM(require("lodash/set"));
var import_has = __toESM(require("lodash/has"));
var cache = {};
var setCache = (path, value) => {
  (0, import_set.default)(cache, path, value);
};
var getCache = (path, defaultValue = void 0) => {
  return (0, import_get.default)(cache, path, defaultValue);
};
var isCache = (path) => {
  return (0, import_has.default)(cache, path);
};

// ../utils/lodashUtils.js
var import_get2 = __toESM(require("lodash/get"));
var import_isNil = __toESM(require("lodash/isNil"));
var import_isEmpty = __toESM(require("lodash/isEmpty"));
var import_defaultTo = __toESM(require("lodash/defaultTo"));
var import_isFunction = __toESM(require("lodash/isFunction"));
var safeGet = (obj, path, defaultValue) => {
  const value = (0, import_get2.default)(obj, path);
  return (0, import_defaultTo.default)(value, defaultValue);
};
var safeGetString = (obj, path, defaultValue) => {
  const value = (0, import_get2.default)(obj, path);
  const result = (0, import_defaultTo.default)(value, defaultValue);
  if (typeof result !== "string")
    return defaultValue;
  return result;
};
var safeGetBoolean = (obj, path, defaultValue) => {
  const value = (0, import_get2.default)(obj, path);
  const result = (0, import_defaultTo.default)(value, defaultValue);
  if (typeof result !== "boolean")
    return defaultValue;
  return result;
};
var isNilOrEmpty = (value) => {
  return (0, import_isNil.default)(value) || (0, import_isEmpty.default)(value);
};
var safeGetFunction = (obj, path, defaultValue) => {
  const value = (0, import_get2.default)(obj, path);
  const result = (0, import_defaultTo.default)(value, defaultValue);
  if ((0, import_isFunction.default)(result))
    return result;
  return defaultValue;
};

// ../utils/jsonParseSafe.js
var jsonParseSafe = (json, reviver) => {
  try {
    return JSON.parse(json, reviver);
  } catch (error) {
    console.error("\u{1F680} ~ jsonParseSafe ~ error:", error);
    return {};
  }
};

// ../utils/jsonStringifySafe.js
function jsonStringifySafe(obj, replacer, spaces) {
  try {
    return JSON.stringify(obj, replacer, spaces);
  } catch (err) {
    console.error(err);
    return "{}";
  }
}

// ../utils/componentUtils.jsx
var import_react = __toESM(require("react"));
var import_find = __toESM(require("lodash/find"));
var import_InfoCircleOutlined = __toESM(require("@ant-design/icons/InfoCircleOutlined"));
var findChildren = (children, componentName) => {
  return (0, import_find.default)(children, (item) => {
    return safeGetString(item, "props.__componentName", "") === componentName;
  });
};
var flattenChildren = (children) => {
  return import_react.default.Children.toArray(children).reduce((flattened, child) => {
    if (child.type === import_react.default.Fragment) {
      return flattened.concat(flattenChildren(child.props.children));
    }
    return flattened.concat(child);
  }, []);
};
var getDescriptionTooltip = (description, popupContainerRef) => {
  if (!description)
    return null;
  return {
    icon: /* @__PURE__ */ import_react.default.createElement(import_InfoCircleOutlined.default, null),
    getPopupContainer: () => popupContainerRef && popupContainerRef.current,
    title: description
  };
};
var RenderHiddenDiv = import_react.default.forwardRef((_, ref) => {
  return /* @__PURE__ */ import_react.default.createElement("div", { ref, style: { display: "none" } });
});

// ../utils/state.js
var import_react2 = require("react");
var AppContext = (0, import_react2.createContext)({});
var getAtPath = (obj, path, defValue) => {
  if (!path)
    return void 0;
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  const result = pathArray.reduce((prevObj, key) => prevObj && prevObj[key], obj);
  return result === void 0 ? defValue : result;
};
var setAtPath = (obj, path, value) => {
  let baseSchema = Object.assign({}, obj);
  let schema = baseSchema;
  if (path.length === 0) {
    Object.assign(schema, value);
  } else {
    const pList = path.split(".");
    const len = pList.length;
    for (let i = 0; i < len - 1; i++) {
      const elem = pList[i];
      if (!schema[elem])
        schema[elem] = {};
      schema = schema[elem];
    }
    schema[pList[len - 1]] = value;
  }
  return baseSchema;
};

// ../utils/useAppContext.js
var import_react3 = require("react");
var useAppContext = () => {
  const context = (0, import_react3.useContext)(AppContext);
  if (context === void 0) {
    throw new Error("Please wrap your component inside AppContext");
  }
  return context;
};

// ../utils/error-utils/components/ErrorDrawer.jsx
var import_drawer = __toESM(require("antd/es/drawer"));
var import_flex = __toESM(require("antd/es/flex"));
var import_typography2 = __toESM(require("antd/es/typography"));
var import_float_button = __toESM(require("antd/es/float-button"));
var import_react5 = __toESM(require("react"));

// ../utils/error-utils/utils.js
var safeGetError = (error) => {
  return {
    message: error?.message || "",
    stacks: (error.stack || error.stacktrace || "").split("\n").slice(1),
    timestamp: /* @__PURE__ */ new Date()
  };
};
var handleError = (rawError) => {
  globalErrors.setError(rawError);
};

// ../utils/error-utils/constants.js
var isError = function(e) {
  return e && e.stack && e.message;
};
var GlobalErrors = class {
  errors = [];
  constructor() {
    this.errors = [];
  }
  setError = (rawError) => {
    if (!isError(rawError))
      return;
    const error = safeGetError(rawError);
    this.errors.unshift(error);
    if (this.errors.length > 15) {
      this.errors.shift();
    }
  };
};
var globalErrors = new GlobalErrors();

// ../utils/error-utils/components/ErrorItem.jsx
var import_react4 = __toESM(require("react"));
var import_collapse = __toESM(require("antd/es/collapse"));
var import_space = __toESM(require("antd/es/space"));
var import_typography = __toESM(require("antd/es/typography"));
var { Text } = import_typography.default;
function ErrorItem({ message = "", stacks = [], timestamp }) {
  return /* @__PURE__ */ import_react4.default.createElement(
    import_collapse.default,
    {
      size: "small",
      items: [
        {
          key: "1",
          label: /* @__PURE__ */ import_react4.default.createElement(Text, null, /* @__PURE__ */ import_react4.default.createElement(Text, { style: { color: "#d9d9d9" } }, timestamp.toLocaleString(), " - "), /* @__PURE__ */ import_react4.default.createElement(Text, { type: "danger" }, "Error:", " ", /* @__PURE__ */ import_react4.default.createElement(Text, { type: "danger", style: { fontWeight: "bold" } }, message))),
          children: /* @__PURE__ */ import_react4.default.createElement(import_space.default, { direction: "vertical", size: "small" }, stacks.map((item) => /* @__PURE__ */ import_react4.default.createElement(Text, { style: { color: "#595959" } }, item)))
        }
      ]
    }
  );
}

// ../utils/error-utils/components/ErrorDrawer.jsx
var import_WarningOutlined = __toESM(require("@ant-design/icons/WarningOutlined"));
var import_CloseOutlined = __toESM(require("@ant-design/icons/CloseOutlined"));

// ../utils/error-utils/components/useErrors.js
var useErrors = () => {
  const { updateContext, appContext } = useAppContext();
  const isShowWarningIcon = appContext?.state?.errors?.isShowWarningIcon ?? false;
  const setShowWarningIcon = (value) => {
    if (isShowWarningIcon === value)
      return;
    updateContext("state.errors.isShowWarningIcon", value);
  };
  return {
    isShowWarningIcon,
    setShowWarningIcon
  };
};

// ../utils/error-utils/components/ErrorDrawer.jsx
var { Text: Text2 } = import_typography2.default;
function ErrorDrawer() {
  const [open, setOpen] = (0, import_react5.useState)(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onCloseDrawer = () => {
    setOpen(false);
  };
  const { isShowWarningIcon, setShowWarningIcon } = useErrors();
  const onClickCloseIcon = () => {
    setShowWarningIcon(false);
  };
  const errorMessage = globalErrors.errors.length === 0 ? `You don't have any error` : `An error occurred, but don\u2019t worry \u2014 it's already been sent to us!`;
  return /* @__PURE__ */ import_react5.default.createElement(import_react5.default.Fragment, null, isShowWarningIcon ? /* @__PURE__ */ import_react5.default.createElement(import_react5.default.Fragment, null, /* @__PURE__ */ import_react5.default.createElement(
    import_float_button.default,
    {
      danger: true,
      icon: /* @__PURE__ */ import_react5.default.createElement(import_WarningOutlined.default, null),
      type: "default",
      style: { bottom: 30, right: 30 },
      onClick: showDrawer
    }
  ), /* @__PURE__ */ import_react5.default.createElement(
    import_CloseOutlined.default,
    {
      onClick: onClickCloseIcon,
      style: { position: "absolute", bottom: 65, right: 30, width: 10, height: 10, zIndex: 100 }
    }
  )) : null, /* @__PURE__ */ import_react5.default.createElement(
    import_drawer.default,
    {
      title: "Exceptions",
      closeIcon: true,
      placement: "bottom",
      closable: false,
      open,
      key: "bottom",
      getContainer: false,
      onClose: onCloseDrawer
    },
    /* @__PURE__ */ import_react5.default.createElement(import_flex.default, { vertical: true, gap: 4 }, /* @__PURE__ */ import_react5.default.createElement(Text2, { style: { color: "#2f54eb" } }, errorMessage), globalErrors.errors.map((item, index) => /* @__PURE__ */ import_react5.default.createElement(ErrorItem, { key: index + item.message, ...item })))
  ));
}

// ../utils/error-utils/components/DefaultFailback.jsx
var import_react6 = __toESM(require("react"));
var import_ReloadOutlined = __toESM(require("@ant-design/icons/ReloadOutlined"));
var import_button = __toESM(require("antd/es/button"));
var import_flex2 = __toESM(require("antd/es/flex"));
var import_typography3 = __toESM(require("antd/es/typography"));
var { Text: Text3 } = import_typography3.default;
function DefaultFailback({ message = "", onRetry = () => {
} }) {
  return /* @__PURE__ */ import_react6.default.createElement(import_react6.default.Fragment, null, /* @__PURE__ */ import_react6.default.createElement(import_flex2.default, { style: { padding: "4px" }, gap: 4 }, /* @__PURE__ */ import_react6.default.createElement(Text3, { type: "danger" }, "Error:", " ", /* @__PURE__ */ import_react6.default.createElement(Text3, { type: "danger", style: { fontWeight: "bold" } }, message)), /* @__PURE__ */ import_react6.default.createElement(import_button.default, { size: "small", shape: "circle", icon: /* @__PURE__ */ import_react6.default.createElement(import_ReloadOutlined.default, null), onClick: onRetry })));
}

// ../utils/error-utils/withErrorBoundary.jsx
var import_react_error_boundary2 = require("react-error-boundary");

// ../utils/error-utils/components/ErrorFailback.jsx
var import_react7 = __toESM(require("react"));
var import_react_error_boundary = require("react-error-boundary");
function ErrorFailback({ error: rawError }) {
  const error = safeGetError(rawError);
  const { resetBoundary } = (0, import_react_error_boundary.useErrorBoundary)();
  const { setShowWarningIcon } = useErrors();
  (0, import_react7.useEffect)(() => {
    setShowWarningIcon(true);
  }, []);
  const handleClickRetry = () => {
    setShowWarningIcon(false);
    resetBoundary();
  };
  return /* @__PURE__ */ import_react7.default.createElement(DefaultFailback, { message: error.message, onRetry: handleClickRetry });
}

// ../utils/error-utils/withErrorBoundary.jsx
function withErrorBoundary(component) {
  return (0, import_react_error_boundary2.withErrorBoundary)(component, {
    FallbackComponent: ErrorFailback,
    onError: handleError
  });
}

// ../utils/formula.js
var import_liquidjs = require("liquidjs");

// ../utils/formulaFilters.js
function liquidjsPluralize(Liquid2) {
  this.registerFilter("pluralize", (n, singular, plural) => pluralize(n, singular, plural));
}
function pluralize(n, singular, plural = null) {
  const num = Number(n);
  if (num === 1) {
    return singular;
  } else if (plural === null) {
    return `${singular}s`;
  } else {
    return plural;
  }
}
function liquidjsAssignProperty(Liquid2) {
  this.registerFilter("assign_prop", (obj, key, value) => assignProperty(obj, key, value));
}
function assignProperty(obj, key, value) {
  return Object.assign({}, obj, { [key]: value });
}
function liquidjsGroupBy(Liquid2) {
  this.registerFilter("group_by", (list, key) => groupBy(list, key));
}
function groupBy(list = [], key) {
  const items = {};
  list.forEach((item) => {
    const itemKey = item[key] || "";
    if (!items[itemKey]) {
      items[itemKey] = [];
    }
    items[itemKey].push(item);
  });
  return Object.keys(items).map((groupKey) => ({ name: groupKey, items: items[groupKey] }));
}
function liquidjsWhereExp(Liquid2) {
  const liquidInstance = new Liquid2({ cache: true });
  this.registerFilter(
    "where_exp",
    (list, variable, expression) => whereExp(liquidInstance, list, variable, expression)
  );
}
function whereExp(liquidInstance, list, variable, expression) {
  return list.filter((v) => !!liquidInstance.evalValueSync(expression, { [variable]: v }));
}

// ../utils/formula.js
var import_isInteger = __toESM(require("lodash/isInteger"));
var import_isFinite = __toESM(require("lodash/isFinite"));
var import_isNil2 = __toESM(require("lodash/isNil"));
var engine = new import_liquidjs.Liquid({
  cache: true
});
engine.plugin(liquidjsPluralize);
engine.plugin(liquidjsAssignProperty);
engine.plugin(liquidjsGroupBy);
engine.plugin(liquidjsWhereExp);
var FORMULA_REGEX = /^{{([\S\s]*)}}$|^{!(([\S\s]*)!!)?([\S\s]*)!}$/;
var FORMULA_INVALID = /^{{(\.([\S\s]*))|(([\S\s]*)\.)}}$/;
var FORMULA_INVALID_2 = /^[\S\s]*\.{2}[\S\s]*$/;
var cachedTemplates = {};
function isExpression(value, validateOnChange = false) {
  if (typeof value !== "string") {
    return false;
  }
  const matches = value.match(FORMULA_REGEX);
  if (!matches || matches.length < 2) {
    return false;
  }
  if (validateOnChange && (FORMULA_INVALID.test(value) || FORMULA_INVALID_2.test(value))) {
    return false;
  }
  return true;
}
var handlePostProcessing = (template, transformation) => {
  if (transformation === "NormaliseOrderedLists") {
    let htmlContent = template;
    let tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;
    let liCounter = 0;
    (tempElement.querySelectorAll("*") || []).forEach((element) => {
      if (element.tagName === "OL") {
        let nestedLiElements = element.querySelectorAll("li");
        let olLiCounter = 0;
        (nestedLiElements || []).forEach((li) => {
          if (li.parentElement === element) {
            olLiCounter++;
          }
        });
        if (liCounter > 0 && !element.hasAttribute("type")) {
          element.setAttribute("start", liCounter + 1);
        }
        liCounter += olLiCounter;
      }
    });
    return tempElement.innerHTML.replace(/>\s+</g, "><").replace(/\s+</g, "<").replace(/>\s+/g, ">").replace(/<\s+/g, "<").replace(/\s+>/g, ">").replace(/\s+$/g, "").replace(/\s{2,}/g, " ").trim();
  } else if (transformation === "ParseJSON") {
    let response = null;
    try {
      response = JSON.parse(template);
    } catch (e) {
      console.error(e);
    }
    return response;
  }
};
function tryParseExpression(value, state) {
  if (!isExpression(value)) {
    return value;
  }
  const matches = value.match(FORMULA_REGEX);
  try {
    const isFormula = typeof matches[1] === "string" && typeof matches[2] === "undefined";
    if (isFormula) {
      const innerFormulaText = matches[1].replaceAll("\n", "");
      return engine.evalValueSync(innerFormulaText, state);
    }
    if (matches.length >= 4 && typeof matches[4] === "string") {
      const innerTemplateText = matches[4].replaceAll("\n", "");
      if (cachedTemplates[innerTemplateText]) {
        const result2 = engine.renderSync(cachedTemplates[innerTemplateText], state);
        if (matches[3]) {
          return handlePostProcessing(result2, matches[3]);
        }
      }
      cachedTemplates[innerTemplateText] = engine.parse(innerTemplateText);
      const result = engine.renderSync(cachedTemplates[innerTemplateText], state);
      if (matches[3]) {
        return handlePostProcessing(result, matches[3]);
      }
      return result;
    }
  } catch (e) {
    console.error(e);
  }
  return value;
}
function parseData(data, state) {
  if (data === null || typeof data === "undefined") {
    return data;
  }
  if (typeof data === "string") {
    return tryParseExpression(data, state);
  }
  if (Array.isArray(data)) {
    return data.map((el) => parseData(el, state));
  }
  if (typeof data !== "object") {
    return tryParseExpression(data, state);
  }
  const parsedData = {};
  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (Array.isArray(value)) {
      parsedData[key] = value.map((child) => {
        return parseData(child, state);
      });
      return;
    }
    if (typeof value === "object") {
      parsedData[key] = parseData(value, state);
      return;
    }
    if (typeof value === "string") {
      parsedData[key] = tryParseExpression(value, state);
      return;
    }
    parsedData[key] = value;
  });
  return parsedData;
}
var tryParseFormulaToString = (data, state, defaultValue) => {
  const actualValue = parseData(data, state);
  if (typeof actualValue !== "string") {
    return defaultValue;
  }
  return actualValue;
};
var tryParseFormulaToNumber = (data, state, defaultValue) => {
  const actualValue = parseData(data, state);
  if (!(0, import_isFinite.default)(actualValue)) {
    return defaultValue;
  }
  return actualValue;
};
var tryParseFormulaToInteger = (data, state, defaultValue) => {
  const actualValue = parseData(data, state);
  if (!(0, import_isInteger.default)(actualValue)) {
    return defaultValue;
  }
  return actualValue;
};
var tryParseFormulaToArray = (data, state, defaultValue) => {
  const actualValue = parseData(data, state);
  if (!Array.isArray(actualValue)) {
    return defaultValue;
  }
  return actualValue;
};
var PARSE_MAP = {
  number: tryParseFormulaToNumber,
  integer: tryParseFormulaToInteger,
  string: tryParseFormulaToString,
  array: tryParseFormulaToArray
};
var tryParseFormulaToType = (type, data, state, defaultValue) => {
  const func = safeGet(PARSE_MAP, type, (data2, state2) => parseData(data2, state2));
  return func(data, state, defaultValue);
};

// ../utils/jsonata.js
var import_jsonata = __toESM(require("jsonata"));
function transformData(data, transform) {
  try {
    const expression = (0, import_jsonata.default)(transform);
    return expression.evaluate(data);
  } catch (e) {
    return transform;
  }
}

// ../utils/api.js
var import_axios = __toESM(require("axios"));
var import_utils4 = require("utils");
var applicationName = process.env.APPLICATION;
var handleCallApi = async (data, state, key, body) => {
  try {
    const { transformInput = "", transformOutput = "", outputStatePath = "" } = data;
    const config = (0, import_utils4.parseData)(data.config, {
      ...state,
      ...body
    });
    const outputStatePathTransform = (0, import_utils4.parseData)(outputStatePath, state);
    let payload = { ...config };
    let transformedPayload = null;
    if (transformInput && transformInput.length) {
      if (transformInput.startsWith("{!") && transformInput.endsWith("!}")) {
        transformedPayload = (0, import_utils4.parseData)(transformInput, body);
      } else {
        transformedPayload = await (0, import_utils4.transformData)(body, transformInput);
      }
    }
    if (transformedPayload || body?.data) {
      const requestData = transformedPayload || body.data;
      if (payload?.method?.toUpperCase() === "GET") {
        payload.params = Object.assign({}, payload.params, requestData);
      } else {
        payload.data = requestData;
      }
    }
    if (body?.params) {
      payload.params = Object.assign({}, payload.params, body.params);
    }
    if (payload.data && payload?.headers?.["Content-Type"] === "multipart/form-data") {
      const formData = new FormData();
      Object.keys(payload.data).forEach((key2) => {
        const value = payload.data[key2];
        if (value && typeof value === "object" && value.base64 && value.name) {
          const file = (0, import_utils4.base64ToFile)(value.base64, value.name);
          formData.append(key2, file);
        } else {
          formData.append(key2, value);
        }
      });
      payload.data = formData;
    }
    let response;
    const { useProxy } = data.config;
    if (useProxy) {
      response = await (0, import_axios.default)({
        method: "POST",
        url: `/api/invoke/${applicationName}/${key}`,
        headers: payload.headers,
        data: payload.data,
        params: payload.params
        // baseURL: 'http://localhost:8000', // for local development
      });
    } else {
      response = await (0, import_axios.default)(payload, { timeout: 30 });
    }
    if (transformOutput && transformOutput.length) {
      const result = await (0, import_utils4.transformData)(Object.assign({}, body, { data: response.data }), transformOutput);
      return {
        key: outputStatePathTransform ? outputStatePathTransform : key,
        data: result
      };
    }
    return {
      key: outputStatePathTransform ? outputStatePathTransform : key,
      data: response.data
    };
  } catch (error) {
    console.error(error);
    return error;
  }
};
var handleApiResponse = async (requests, updateContext, authUrl) => {
  const responses = await Promise.all(requests.map((r) => r.catch((e) => e)));
  let errors = [];
  for (let response of responses) {
    if (response instanceof Error) {
      errors.push(response);
    }
    updateContext(response.key, response.data);
  }
  updateContext("isLoaded", true);
  if (errors.length) {
    errors.forEach((error) => {
      console.error(error.message);
      if (error?.response?.status === 401 && authUrl) {
        window.location.href = authUrl;
      }
    });
    throw new Error("Something went wrong. Please screenshot this error, please refresh your browser and try again.");
  }
};
var downloadFile = async (fileUrl, filename) => {
  const response = await (0, import_axios.default)({
    url: fileUrl,
    method: "GET",
    responseType: "blob"
  });
  const blob = new Blob([response.data], {
    type: response.headers["content-type"]
  });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  if (filename) {
    link.download = filename;
  } else {
    link.download = (0, import_utils4.getFileName)(fileUrl);
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// ../utils/storage.js
var import_react8 = require("react");
var storage = {
  setValue: (key, value) => {
    localStorage.setItem(key, value);
  },
  getValue: (key) => {
    return localStorage.getItem(key) || null;
  },
  deleteValue: (key) => {
    localStorage.removeItem(key);
  }
};
var useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = (0, import_react8.useState)(() => {
    if (typeof window !== "undefined") {
      try {
        const storedValue = localStorage.getItem(key);
        return storedValue === null ? defaultValue : JSON.parse(storedValue);
      } catch (e) {
        return defaultValue;
      }
    }
    return defaultValue;
  });
  (0, import_react8.useEffect)(() => {
    if (typeof window === "undefined") {
      return;
    }
    const listener = (e) => {
      if (e.storageArea === localStorage && e.key === key) {
        setValue(e.newValue ? JSON.parse(e.newValue) : e.newValue);
      }
    };
    window.addEventListener("storage", listener);
    return () => {
      window.removeEventListener("storage", listener);
    };
  }, [key, defaultValue]);
  const setValueInLocalStorage = (newValue) => {
    setValue((currentValue) => {
      const result = typeof newValue === "function" ? newValue(currentValue) : newValue;
      if (typeof window !== "undefined") {
        localStorage.setItem(key, JSON.stringify(result));
        window.dispatchEvent(
          new StorageEvent("storage", {
            storageArea: localStorage,
            key,
            newValue: JSON.stringify(result)
          })
        );
      }
      return result;
    });
  };
  return [value, setValueInLocalStorage];
};

// ../utils/styles.js
var import_isNil3 = __toESM(require("lodash/isNil"));
var import_antd = require("antd");
var import_uuid = require("uuid");
var import_cssinjs = require("@ant-design/cssinjs");
function convertToKebabCase(str) {
  return str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}
var convertStyles = (styles) => Object.entries(styles).filter(([_, v]) => ![null, void 0].includes(v) && String(v).trim() !== "").reduce((acc, [key, value]) => {
  let val = value;
  if (typeof value === "string" && !value.includes("!important")) {
    val = `${value} !important`;
  }
  acc[convertToKebabCase(key)] = val;
  return acc;
}, {});
var loadStyles = async (targetRoot, styles = []) => {
  const styleLoadingPromises = styles.map(
    (href) => new Promise((resolve, reject) => {
      const link = document.createElement("link");
      link.setAttribute("rel", "stylesheet");
      link.setAttribute("href", href);
      link.onload = () => resolve(href);
      link.onerror = () => reject(new Error(`Error loading ${href}`));
      targetRoot.appendChild(link);
    })
  );
  await Promise.all(styleLoadingPromises).catch((error) => {
    console.error("Error loading styles:", error);
  });
};
var useResponsiveStyle = (style = {}) => {
  const { token, theme: antdTheme } = import_antd.theme.useToken();
  let styleParse = style;
  if (typeof styleParse === "string") {
    try {
      styleParse = JSON.parse(styleParse);
    } catch (error) {
      console.error("Error parsing style:", error);
      styleParse = {};
    }
  }
  const className = `style-${(0, import_uuid.v4)()}`;
  const { tablet = {}, desktop = {}, mobile = {}, ...otherProps } = styleParse;
  const otherStyled = convertStyles(otherProps);
  const tabletStyled = convertStyles(tablet);
  const desktopStyled = convertStyles(desktop);
  const mobileStyled = convertStyles(mobile);
  (0, import_cssinjs.useStyleRegister)(
    {
      theme: antdTheme,
      token,
      path: [className]
    },
    () => ({
      [`.${className}`]: {
        ...otherStyled,
        "@container (width < 760px)": mobileStyled,
        "@container (width >= 985px)": desktopStyled,
        "@container (width >= 760px) and (width < 985px)": tabletStyled
      }
    })
  );
  return {
    styled: {},
    className
  };
};
var injectStyle = (styleCtn = "", selectors = "root") => {
  if (!styleCtn)
    return;
  let containerDiv = document.querySelector(selectors);
  if ((0, import_isNil3.default)(containerDiv)) {
    containerDiv = document.getElementById("root");
  }
  if ((0, import_isNil3.default)(containerDiv)) {
    containerDiv = document.head;
  }
  const styleElement = document.createElement("style");
  styleElement.textContent = styleCtn;
  if (!(0, import_isNil3.default)(containerDiv.shadowRoot)) {
    containerDiv.shadowRoot.appendChild(styleElement);
    return;
  }
  containerDiv.appendChild(styleElement);
};

// ../utils/components.jsx
var components_exports = {};
__export(components_exports, {
  ConditionalFragment: () => ConditionalFragment,
  FormulaWrapper: () => FormulaWrapper,
  NextComponent: () => NextComponent,
  NextComponentErrorBoundary: () => NextComponentErrorBoundary,
  RootElement: () => RootElement,
  elementToReact: () => elementToReact
});
var import_react9 = __toESM(require("react"));
var import_react_router_dom = require("react-router-dom");
var import_skeleton = __toESM(require("antd/es/skeleton"));
var import_utils5 = require("utils");
var import_notification = __toESM(require("antd/es/notification"));
var import_mergeWith = __toESM(require("lodash/mergeWith"));
var import_isArray = __toESM(require("lodash/isArray"));
var import_structured_clone = __toESM(require("@ungap/structured-clone"));
var import_get3 = __toESM(require("lodash/get"));

// ../utils/refUtils.js
var assignRef = (el, ref) => {
  if (!el)
    return;
  if (ref && typeof ref === "function") {
    ref(el);
  } else if (ref && "current" in ref) {
    ref.current = el;
  }
};
var assignRefToProps = (props, ref, onRef = (ref2) => {
}) => {
  try {
    return Object.assign({}, props, {
      ref: (el) => {
        assignRef(el, ref);
        onRef(el);
      }
    });
  } catch (error) {
    return props;
  }
};
var switchRefToForward = (parentRef, isStudio = false) => {
  if (isStudio) {
    return {
      studioRef: parentRef,
      componentRef: void 0
    };
  }
  return {
    studioRef: void 0,
    componentRef: parentRef
  };
};

// ../utils/rootElements.js
var import_isNil4 = __toESM(require("lodash/isNil"));
var initRootElement = (element) => {
  if (!(element instanceof Document || element instanceof ShadowRoot || element instanceof Element)) {
    throw new Error("Invalid input: Expected a Document, ShadowRoot, or Element instance.");
  }
  const isShadowContext = (el) => el instanceof ShadowRoot || el.shadowRoot;
  element.getElementsByClassName = function(className) {
    if (isShadowContext(this)) {
      return (this.shadowRoot || this).querySelectorAll(`.${className}`);
    }
    return document.getElementsByClassName(className);
  };
  element.getElementById = function(id) {
    if (isShadowContext(this)) {
      return (this.shadowRoot || this).querySelector(`#${id}`);
    }
    return document.getElementById(id);
  };
  element.getElementsByTagName = function(tagName) {
    if (isShadowContext(this)) {
      return (this.shadowRoot || this).querySelector(tagName);
    }
    return document.getElementsByTagName(tagName);
  };
  element.appendChild = function(child) {
    if (isShadowContext(this)) {
      return ShadowRoot.prototype.appendChild.call(this, child);
    }
    return Element.prototype.appendChild.call(this, child);
  };
  return element;
};
var getElementById = (id) => {
  if (!id || !document)
    return null;
  let el = document.getElementById(id);
  if (el)
    return el;
  const temp = document.getElementsByClassName("unified-react-container");
  if (!temp || temp.length !== 1) {
    return null;
  }
  const container = temp[0];
  if ((0, import_isNil4.default)(container.shadowRoot))
    return null;
  return container.shadowRoot.getElementById(id);
};

// ../utils/components.jsx
var version = process.env.UNIFIED_REACT_VERSION;
var applicationConfig = process.env.APPLICATION_CONFIG;
var { allComponentTypes: allComponentTypesDefault = [] } = applicationConfig;
var parseDynamicValues = (startProp, output, keyPath, dynamicProps, componentState, inputPropDefinition = {}, validateOnChange) => {
  if (typeof startProp === "undefined" || startProp === null) {
    return;
  }
  Object.keys(startProp).forEach((key) => {
    if (key === "initialConfig" || key === "children" || key === "form" || inputPropDefinition[key] && inputPropDefinition[key].type === "event") {
      output[key] = startProp[key];
      return;
    }
    if ((0, import_utils5.isExpression)(startProp[key], validateOnChange)) {
      output[key] = (0, import_utils5.parseData)(startProp[key], componentState);
      dynamicProps[keyPath ? `${keyPath}.${key}` : key] = output[key];
    } else if (typeof startProp[key] === "object" && !Array.isArray(startProp[key])) {
      output[key] = {};
      parseDynamicValues(
        startProp[key],
        output[key],
        key,
        dynamicProps,
        componentState,
        inputPropDefinition,
        validateOnChange
      );
    } else if (Array.isArray(startProp[key])) {
      output[key] = startProp[key].map((el, idx) => {
        if (typeof el === "object") {
          let arrayOutput = {};
          parseDynamicValues(
            el,
            arrayOutput,
            `${key}[${idx}]`,
            dynamicProps,
            componentState,
            inputPropDefinition,
            validateOnChange
          );
          return arrayOutput;
        } else {
          if ((0, import_utils5.isExpression)(el, validateOnChange)) {
            const parsedElement = (0, import_utils5.parseData)(el, componentState);
            dynamicProps[`${key}[${idx}]`] = parsedElement;
            return parsedElement;
          }
          return el;
        }
      });
    } else {
      output[key] = startProp[key];
    }
  });
};
var NextComponent = import_react9.default.forwardRef((props, parentRef) => {
  const {
    ChildComponent,
    enableSkeleton,
    formulaState: parentFormulaState,
    __componentDefinition: { inputProps = {} } = {},
    validateOnChange
  } = props;
  const { appContext, updateContext } = (0, import_utils5.useAppContext)();
  const [hasCorrectState, setHasCorrectState] = (0, import_react9.useState)(typeof parentFormulaState === "undefined");
  (0, import_react9.useEffect)(() => {
    setHasCorrectState(true);
  }, [parentFormulaState]);
  const { initialConfig = {}, propOverrides = {} } = props;
  const componentState = Object.assign(
    {},
    typeof parentFormulaState !== "undefined" ? parentFormulaState : {},
    { app: appContext },
    initialConfig,
    propOverrides
  );
  const dynamicProps = {};
  const nextProps = {};
  parseDynamicValues(props, nextProps, "", dynamicProps, componentState, inputProps, validateOnChange);
  const nextPropsPrimitiveObject = {};
  const nextPropsPrimitives = [];
  Object.keys(nextProps).forEach((key) => {
    if (typeof nextProps[key] !== "object" && typeof nextProps[key] !== "function") {
      nextPropsPrimitives.push(nextProps[key]);
      nextPropsPrimitiveObject[key] = nextProps[key];
    }
  });
  const { nameProp, pathProp, formulaState } = nextProps;
  if (nameProp && pathProp && formulaState) {
    nextProps[nameProp] = (0, import_get3.default)(formulaState, pathProp);
  }
  (0, import_react9.useEffect)(() => {
    if (nextProps.statePath && nextProps.name) {
      const simplifiedName = nextProps.name.startsWith("form.") ? nextProps.name.replace("form.", "") : nextProps.name;
      updateContext(`state.${nextProps.statePath}.${simplifiedName}`, nextPropsPrimitiveObject);
    }
  }, [JSON.stringify(nextPropsPrimitiveObject)]);
  const childRef = (0, import_react9.useRef)();
  const { id } = nextProps;
  (0, import_react9.useEffect)(() => {
    if (childRef && childRef.current && id) {
      updateContext(`components.${id}`, childRef.current);
    }
  }, [childRef]);
  const finalProps = {
    ...nextProps,
    ...propOverrides
  };
  const component = (0, import_react9.useMemo)(() => {
    const propsWithRef = assignRefToProps(finalProps, parentRef, (ref) => childRef.current = ref);
    return import_react9.default.createElement(ChildComponent, propsWithRef);
  }, [JSON.stringify(nextPropsPrimitives), JSON.stringify(dynamicProps), childRef, parentRef]);
  const isClient = typeof window !== "undefined";
  if (enableSkeleton && !hasCorrectState && isClient) {
    return import_react9.default.createElement(import_skeleton.default, {
      active: true,
      paragraph: { rows: 1 }
    });
  }
  return component;
});
var NextComponentErrorBoundary = (0, import_utils5.withErrorBoundary)(NextComponent);
var FormulaWrapper = (ChildComponent, enableSkeleton = true) => {
  const { enableSkeleton: enableSkeletonGlobal } = applicationConfig;
  let shouldEnableSkeleton = enableSkeleton;
  if (typeof enableSkeletonGlobal !== void 0) {
    shouldEnableSkeleton = enableSkeletonGlobal;
  }
  return (props) => import_react9.default.createElement(NextComponentErrorBoundary, {
    ChildComponent,
    enableSkeleton: shouldEnableSkeleton,
    ...props
  });
};
var ConditionalFragment = (props) => {
  const { visible, children = [] } = props;
  if (visible === false) {
    return null;
  }
  return import_react9.default.createElement(import_react9.default.Fragment, {}, children);
};
var elementToReact = (obj, initialConfig = {}, componentEntrypoints = {}, allComponentTypes, configs = {
  transformProps: null,
  transformRenderType: null,
  transformElement: null
}) => {
  if (typeof window !== "undefined") {
    initialConfig.rootElement = initRootElement(initialConfig.rootElement);
  }
  if (typeof obj === "object" && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map((item) => elementToReact(item, initialConfig, componentEntrypoints, allComponentTypes, configs));
    } else {
      for (const key in obj) {
        if (key !== "__isComponent" && !(key === "children" && obj.__isComponent) && Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = elementToReact(obj[key], initialConfig, componentEntrypoints, allComponentTypes, configs);
        }
      }
      if (obj.__isComponent) {
        const { type, props, children } = obj;
        let actualType = type;
        if (type === "Fragment") {
          actualType = ConditionalFragment;
        } else {
          if (allComponentTypes.includes(type)) {
            actualType = componentEntrypoints[type] ? componentEntrypoints[type] : ConditionalFragment;
          }
        }
        const renderType = FormulaWrapper(actualType);
        const elementProps = type === "Fragment" ? {
          children,
          key: props.key,
          visible: props.visible
        } : Object.assign({}, props, { initialConfig });
        const transformProps = safeGetFunction(configs, "transformProps", (a) => a);
        const finalProps = transformProps(elementProps, type, actualType);
        const transformRenderType = safeGetFunction(configs, "transformRenderType", (a) => a);
        const finalRenderType = transformRenderType(renderType);
        const UIElementChildrens = elementToReact(
          children,
          initialConfig,
          componentEntrypoints,
          allComponentTypes,
          configs
        );
        const UIElement = import_react9.default.createElement(finalRenderType, finalProps, UIElementChildrens);
        const transformElement = safeGetFunction(configs, "transformElement", (a) => a);
        const FinalUIElement = transformElement(UIElement, finalProps);
        return FinalUIElement;
      }
      return obj;
    }
  }
  return obj;
};
var RootElement = (props) => {
  const {
    applicationConfig: applicationConfig2 = {},
    initialConfig,
    elements = [],
    additionalParams = {},
    componentEntrypoints,
    csr = false,
    events: pageEvents = {},
    allComponentTypes = allComponentTypesDefault,
    isStudio = false
  } = props;
  const { api } = applicationConfig2;
  const { rootElement, authUrl = "", disableAPI = false, mockedData = {} } = initialConfig;
  let params = null;
  if (!csr) {
    params = (0, import_react_router_dom.useParams)();
  } else {
    params = additionalParams;
  }
  const navigate = (0, import_react_router_dom.useNavigate)();
  const location = (0, import_react_router_dom.useLocation)();
  let initialConfigWithParams = Object.assign({}, initialConfig, params);
  let [appContext, setAppContext] = (0, import_react9.useState)({
    initialConfig: initialConfigWithParams,
    callback: {},
    isLoaded: false,
    version,
    components: {}
  });
  const updatedContextRef = (0, import_react9.useRef)(appContext);
  const intervalActionKeyRef = (0, import_react9.useRef)({});
  const updateContext = (path = "", newProps) => {
    let updatedContext = updatedContextRef.current || appContext;
    const subState = path === "" ? updatedContext : (0, import_utils5.getAtPath)(updatedContext, path);
    if (typeof newProps === "object" && !Array.isArray(newProps)) {
      let clonedNewProps = newProps;
      try {
        clonedNewProps = (0, import_structured_clone.default)(newProps);
      } catch (e) {
      }
      let clonedSubState = subState;
      if (path !== "" && typeof subState === "object" && subState !== null) {
        try {
          clonedSubState = (0, import_structured_clone.default)(subState);
        } catch (e) {
        }
      }
      const mergedSubState = (0, import_mergeWith.default)(
        clonedSubState,
        clonedNewProps,
        (objValue, srcValue) => (0, import_isArray.default)(srcValue) ? srcValue : void 0
      );
      updatedContext = (0, import_utils5.setAtPath)(updatedContext, path, mergedSubState);
    } else {
      updatedContext = (0, import_utils5.setAtPath)(updatedContext, path, newProps);
    }
    updatedContextRef.current = updatedContext;
    setAppContext(updatedContextRef.current);
  };
  const handleEvent = async (actions = [], formulaState = {}) => {
    let currentContext = updatedContextRef.current || appContext;
    const { components, callback = {} } = currentContext;
    for (let i = 0; i < actions.length; i++) {
      currentContext = updatedContextRef.current;
      try {
        const action = (0, import_utils5.parseData)(actions[i], { ...initialConfig, ...formulaState, app: currentContext });
        const {
          target,
          type,
          method,
          conditions = true,
          args = [],
          successMessage = "",
          progressMessage = "",
          params: params2,
          successUrl = "",
          message,
          isLoading = false,
          loadingKey = "",
          body
        } = action;
        if (type === "invoke") {
          const targetInstance = components[target] || {};
          if (targetInstance[method]) {
            await Promise.resolve(targetInstance[method](...args));
          }
        } else if (type === "updateContext") {
          if (action["key"]) {
            updateContext(action["key"], action?.["value"] || 0);
          }
        } else if (type === "updateLocalStorage") {
          if (action["key"]) {
            localStorage.setItem(action["key"], action?.["value"] || "");
          }
        } else if (type === "localStorageToConText") {
          if (action["key"] && localStorage.getItem(action["key"])) {
            updateContext(action["key"], localStorage.getItem(action["key"]) || 0);
          }
        } else if (type === "removeLocalStorage") {
          if (action["key"]) {
            localStorage.removeItem(action["key"]);
          }
        } else if (type === "api") {
          const maxCount = args.find((arg) => arg.key === "maxLoop");
          const interval = args.find((arg) => arg.key === "interval");
          let isRetryApi = maxCount && interval ? true : false;
          const callbackConfig = {};
          const callbackBody = {};
          args.forEach((argument) => {
            const { key, value } = argument;
            callbackConfig[key] = value;
            callbackBody[key] = value;
          });
          if (!conditions) {
            return;
          }
          const targetApi = callback[target];
          if (targetApi) {
            let count = 0;
            let apiCallSuccess = false;
            const apiHandler = async () => {
              const promises = [
                targetApi(
                  callbackConfig,
                  Object.assign(
                    {},
                    { app: updatedContextRef.current },
                    body ? { data: body } : {},
                    params2 ? { params: params2 } : {},
                    callbackBody
                  )
                )
              ];
              let progressMessageKey = void 0;
              try {
                if (isLoading) {
                  updateContext("isLoaded", false);
                }
                if (progressMessage) {
                  progressMessageKey = `open${Date.now()}`;
                  toast({ type: "info", key: progressMessageKey, message: progressMessage, duration: 0 });
                }
                loadingKey && updateContext(loadingKey, true);
                await (0, import_utils5.handleApiResponse)(promises, updateContext, authUrl);
                if (progressMessageKey) {
                  toast({ type: "destroy", key: progressMessageKey });
                }
                if (successMessage) {
                  toast({ type: "success", message: successMessage });
                }
                if (successUrl) {
                  window.location.href = successUrl;
                }
                apiCallSuccess = true;
              } catch (e) {
                if (isRetryApi) {
                  if (count >= maxCount.value) {
                    updateContext(loadingKey, false);
                    toast({ type: "error", message: `API Error: ${e.message}` });
                  }
                } else {
                  if (progressMessageKey) {
                    toast({ type: "destroy", key: progressMessageKey });
                  }
                  toast({ type: "error", message: `API Error: ${e.message}` });
                }
              } finally {
                if (isRetryApi) {
                  if (count >= maxCount.value) {
                    updateContext(loadingKey, false);
                  }
                } else {
                  loadingKey && updateContext(loadingKey, false);
                }
              }
            };
            if (isRetryApi) {
              for (let j = 0; j < maxCount.value; j++) {
                count++;
                await apiHandler();
                if (apiCallSuccess) {
                  break;
                }
                await new Promise((resolve) => setTimeout(resolve, interval.value));
              }
            } else {
              await apiHandler();
            }
          } else {
            console.error(`Target API: ${target} could not be found!`);
          }
        } else if (type === "confirm") {
          if (!confirm(message)) {
            return false;
          }
        } else if (type === "openLink") {
          const linkArg = args.find((arg) => arg.key === "link");
          if (linkArg.value) {
            window.open(linkArg.value, "_blank");
          }
        } else if (type === "navigate") {
          const linkArg = args.find((arg) => arg.key === "link");
          if (linkArg.value) {
            navigate(linkArg.value);
          }
        } else if (type === "href") {
          const linkArg = args.find((arg) => arg.key === "link");
          if (linkArg.value) {
            window.location.href = window.location.origin + linkArg.value;
          }
        } else if (type === "navigateLink") {
          const linkArg = args.find((arg) => arg.key === "link");
          if (linkArg.value) {
            window.location.href = linkArg.value;
          }
        } else if (type === "download") {
          const linkArg = args.find((arg) => arg.key === "link");
          if (linkArg?.value) {
            const fileNameArg = args.find((arg) => arg.key === "fileName");
            (0, import_utils5.downloadFile)(linkArg.value, fileNameArg?.value || "");
          }
        } else if (type === "ifElse") {
          const { key = "", value = "", formula = "" } = action;
          const { ifTrue = [], ifFalse = [] } = actions[i];
          let isMatch = false;
          if (key !== "" && value !== "") {
            isMatch = (0, import_utils5.getAtPath)(updatedContextRef.current, key) === value;
          } else if (formula !== "") {
            isMatch = formula;
          }
          const actionsToRun = isMatch ? ifTrue : ifFalse;
          await handleEvent(actionsToRun);
        } else if (type === "embedded") {
          if (document) {
            const s = document.createElement("script");
            s.type = "text/javascript";
            s.src = args["application_url"];
            document.body.appendChild(s);
            s.onerror = function() {
              console.log("script not loaded");
            };
            s.onload = function() {
              setTimeout(function() {
                let divEmbeddedSection = document.getElementById(args["embedded_section_id"]);
                if (divEmbeddedSection) {
                  divEmbeddedSection.remove();
                }
                divEmbeddedSection = document.createElement("div");
                divEmbeddedSection.setAttribute("id", args["embedded_section_id"]);
                document.body.appendChild(divEmbeddedSection);
                if (window) {
                  window.UnifiedReact[args["application_name"]].components[args["component_name"]].init({
                    rootElement: divEmbeddedSection,
                    shadow: true,
                    config: action?.["value"]
                  });
                }
              }, 500);
            };
          }
        } else if (type === "scrollToElement") {
          if (document) {
            const elem = document.getElementById(args["key"]);
            if (elem) {
              elem.scrollIntoView({ behavior: "smooth" });
            }
          }
        } else if (type === "callFunction") {
          const functionToCall = (0, import_utils5.getAtPath)(formulaState, method);
          if (typeof functionToCall === "function") {
            functionToCall(...args);
          }
        } else if (type === "copyText") {
          const copyArg = args.find((arg) => arg.key === "text");
          if (copyArg && copyArg.value) {
            navigator.clipboard.writeText(copyArg.value).then(() => {
              toast({ type: "success", message: copyArg.message });
            }).catch((err) => {
              console.error("Failed to copy text: ", err);
            });
          }
        } else if (type === "intervalAction") {
          const { interval, key } = action;
          const { events = [] } = actions[i];
          if (!key) {
            console.error("intervalAction must have a unique key");
            return;
          }
          if (intervalActionKeyRef.current[key]?.intervalId) {
            clearInterval(intervalActionKeyRef.current[key].intervalId);
          }
          if (!interval || Number.isNaN(interval) || interval <= 0) {
            return;
          }
          const validatedInterval = Math.max(Number(interval) || 1e3, 1e3);
          intervalActionKeyRef.current[key] = {
            intervalId: setInterval(async () => {
              if (intervalActionKeyRef.current?.[key]?.isRunning) {
                return;
              }
              intervalActionKeyRef.current[key].isRunning = true;
              try {
                await handleEvent(events);
              } catch (e) {
                console.error(`intervalAction::[${key}] Error when handling events::`, e);
              } finally {
                intervalActionKeyRef.current[key].isRunning = false;
              }
            }, validatedInterval),
            isRunning: false
            // Initialize isRunning to false
          };
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    }
    return true;
  };
  const [notificationInstance, contextHolder] = import_notification.default.useNotification({
    getContainer: () => rootElement
  });
  const toast = ({ type, key = void 0, message = "", duration = 4.5 }) => {
    if (type === "destroy") {
      notificationInstance.destroy(key);
    } else {
      notificationInstance[type]({
        key,
        message,
        duration
      });
    }
  };
  (0, import_react9.useEffect)(() => {
    if (typeof mockedData === "object") {
      const keys = Object.keys(mockedData);
      if (!keys.length)
        return;
      keys.forEach((e) => {
        updateContext(e, mockedData[e]);
      });
    }
  }, [mockedData]);
  (0, import_react9.useEffect)(() => {
    if (disableAPI)
      return;
    const url = window?.location?.href;
    const params2 = new URLSearchParams(url.split("#")[1]);
    const accessToken = params2.get("access_token");
    const isValidToken = accessToken && (0, import_utils5.verifyToken)(accessToken);
    if (isValidToken) {
      localStorage.setItem("token", `Bearer ${accessToken}`);
    }
    const isValidTokenLocalStorage = localStorage.getItem("token") && (0, import_utils5.verifyToken)(localStorage.getItem("token"));
    if (!isValidToken && !isValidTokenLocalStorage && authUrl) {
      window.location.href = authUrl;
      return;
    }
    if (applicationConfig2.enableCaching) {
      const childOfApp = applicationConfig2.enableCaching.app;
      for (const key in childOfApp) {
        if (childOfApp.hasOwnProperty(key)) {
          updateContext(`${key}`, childOfApp[key]);
        }
      }
    }
    if (api) {
      const fetch = async () => {
        const entries = Object.entries(api);
        const promises = [];
        const callback = {};
        for (const [key, value] of entries) {
          const clonedValue = (0, import_structured_clone.default)(value);
          const storedToken = localStorage.getItem("token");
          if (storedToken || accessToken) {
            clonedValue.config.headers.authorization = `${storedToken || accessToken}`;
          }
          if (clonedValue?.handle?.includes("init")) {
            if (clonedValue?.enableCaching === false || clonedValue?.enableCaching === void 0) {
              if (clonedValue?.config) {
                promises.push((0, import_utils5.handleCallApi)(value, initialConfigWithParams, key));
              }
            }
          }
          if (clonedValue.config) {
            callback[key] = async (callbackConfig, body) => {
              return (0, import_utils5.handleCallApi)(clonedValue, Object.assign({}, initialConfigWithParams, callbackConfig), key, body);
            };
          }
        }
        updateContext("callback", callback);
        try {
          promises.length > 0 && await (0, import_utils5.handleApiResponse)(promises, updateContext, authUrl, false);
        } catch (e) {
          toast({ type: "error", message: `API Error: ${e.message}` });
        }
      };
      fetch().then(() => {
        updatedContextRef.current = (0, import_utils5.setAtPath)(updatedContextRef.current, "initialConfig", initialConfigWithParams);
        const { onload = [] } = pageEvents;
        return handleEvent(onload);
      });
    } else {
      setAppContext({ ...appContext, isLoaded: true });
    }
  }, [location.pathname, disableAPI]);
  const { rootElement: _, ...initialConfigProps } = initialConfigWithParams;
  const elementStrings = isStudio ? "" : JSON.stringify(elements);
  const pageElements = (0, import_react9.useMemo)(() => {
    if (!elementStrings)
      return elements;
    try {
      const renderedElements = (0, import_structured_clone.default)(elements);
      return elementToReact(renderedElements, initialConfigWithParams, componentEntrypoints, allComponentTypes);
    } catch (e) {
      console.error(e);
    }
  }, [elementStrings, JSON.stringify(initialConfigProps)]);
  return import_react9.default.createElement(
    import_utils5.AppContext.Provider,
    { value: { appContext, updateContext, toast, handleEvent } },
    import_react9.default.createElement(import_react9.Fragment, {}, [contextHolder, ...pageElements, /* @__PURE__ */ import_react9.default.createElement(import_utils5.ErrorDrawer, null)])
  );
};

// ../utils/form.js
var import_isNil5 = __toESM(require("lodash/isNil"));
var generateObject = (field, value) => {
  const fieldPath = field.split(".").reverse();
  const object = {};
  let temp = object;
  while (fieldPath.length > 0) {
    const field2 = fieldPath.pop();
    if (fieldPath.length === 0) {
      temp[field2] = value;
    } else {
      temp[field2] = {};
      temp = temp[field2];
    }
  }
  return object;
};
var injectParseFormulaIntoRules = (rules = [], state = {}) => {
  const INVALID_DEFAULT_VALUE_MAPS = {
    number: {},
    integer: {},
    string: {},
    array: ""
  };
  return rules.map((item) => ({
    ...item,
    transform: (value) => {
      const type = safeGetString(item, "type", "");
      const invalidDefaultValue = safeGet(INVALID_DEFAULT_VALUE_MAPS, "type", "");
      return tryParseFormulaToType(type, value, state, invalidDefaultValue);
    }
  }));
};
var joinFieldPaths = (...fields) => {
  return fields.filter((item) => !(0, import_isNil5.default)(item) && item !== "").join(".").replaceAll(",", ".");
};

// ../utils/params.js
var getTokenFromUrl = () => {
  const url = window?.location?.href;
  const params = new URLSearchParams(url.split("#")[1]);
  return params.get("access_token");
};

// ../utils/index.js
__reExport(utils_exports, __toESM(require_handlerConfig()), module.exports);

// ../utils/constant.js
var ACTION_TYPES = {
  invoke: "invoke",
  updateContext: "updateContext",
  api: "api",
  confirm: "confirm",
  openLink: "openLink",
  navigate: "navigate",
  download: "download",
  ifElse: "ifElse"
};

// ../utils/date.js
var date_exports = {};
__export(date_exports, {
  get: () => get3,
  getDayWithFormat: () => getDayWithFormat,
  isDateBefore: () => isDateBefore,
  isValid: () => isValid,
  now: () => now,
  subtract: () => subtract
});
var import_dayjs = __toESM(require("dayjs"));
var now = () => (0, import_dayjs.default)();
var get3 = (value) => (0, import_dayjs.default)(value);
var isValid = (value) => {
  if (!value)
    return false;
  return (0, import_dayjs.default)(value).isValid();
};
var isDateBefore = (current, checkDate) => (0, import_dayjs.default)(current).isBefore((0, import_dayjs.default)(checkDate));
var subtract = (num, type = "day") => (0, import_dayjs.default)().subtract(num, type);
var getDayWithFormat = (date, format = "YYYY-MM-DD HH:mm:ss") => {
  if (isValid(date)) {
    return get3(date).format(format);
  }
  return "";
};

// ../utils/string.js
var string_exports = {};
__export(string_exports, {
  toSentenceCase: () => toSentenceCase
});
function toSentenceCase(str) {
  const result = str.replace(/([A-Z])/g, " $1").toLowerCase().trim();
  return result.charAt(0).toUpperCase() + result.slice(1);
}

// ../utils/hash.js
var hash256 = async (input) => {
  const textAsBuffer = new TextEncoder().encode(input);
  const hashBuffer = await window.crypto.subtle.digest("SHA-256", textAsBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hash = hashArray.map((item) => item.toString(16).padStart(2, "0")).join("");
  return hash;
};

// ../utils/auth.js
var import_auth0_spa_js = require("@auth0/auth0-spa-js");
var auth0;
var deleteCookie = (name) => {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};
var initAuth0Sdk = async (domain, clientId, redirectLoginUri) => {
  deleteCookie(`_legacy_auth0.${clientId}.is.authenticated`);
  deleteCookie(`auth0.${clientId}.is.authenticated`);
  auth0 = await (0, import_auth0_spa_js.createAuth0Client)({
    domain,
    clientId,
    authorizationParams: {
      redirect_uri: redirectLoginUri
    }
  });
  return auth0;
};
var getUser = async () => {
  const user = await auth0.getUser();
  return user;
};
var isAuthenticated = () => {
  return auth0.isAuthenticated();
};
var removeQuotes = (str) => {
  if (str.startsWith('"') && str.endsWith('"')) {
    return str.slice(1, -1);
  }
  return str;
};
var getToken = (token) => {
  if (!token) {
    return null;
  }
  if (token.startsWith("Bearer ") || token.startsWith("bearer ")) {
    token = token.substring(7);
  }
  token = removeQuotes(token);
  return token;
};
var parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window.atob(base64).split("").map(function(c) {
      return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    }).join("")
  );
  return JSON.parse(jsonPayload);
};
var verifyToken2 = (token) => {
  if (typeof token !== "string") {
    return false;
  }
  const checkToken = getToken(token);
  const decodedToken = parseJwt(checkToken);
  if (!decodedToken || !decodedToken.exp) {
    return false;
  }
  const currentTime = Math.floor(Date.now() / 1e3);
  return decodedToken.exp > currentTime;
};

// ../utils/file.js
function base64ToFile2(base64, filename) {
  const arr = base64.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
var getFileName2 = (filePath) => {
  const segments = filePath.split(/[\\/]/);
  return segments.pop();
};

// ../utils/loadRemoteComponent.js
var loadRemoteComponent_exports = {};
__export(loadRemoteComponent_exports, {
  getComponentInCache: () => getComponentInCache,
  isComponentCached: () => isComponentCached,
  loadComponent: () => loadComponent,
  loadComponentAndCss: () => loadComponentAndCss,
  loadCss: () => loadCss,
  loadFile: () => loadFile
});
var import_react10 = __toESM(require("react"));
var CssInJs = __toESM(require("@ant-design/cssinjs"));
var jsxRuntime = __toESM(require("react/jsx-runtime"));

// ../utils/package-imports/index.js
var package_imports_exports = {};
__export(package_imports_exports, {
  AntdImports: () => antdImports_exports,
  DndKitCoreImports: () => dndKitCoreImports_exports,
  DndKitSortableImports: () => dndKitSortableImports_exports,
  DndKitUtilitiesImports: () => dndKitUtilitiesImports_exports
});

// ../utils/package-imports/antdImports.js
var antdImports_exports = {};
__export(antdImports_exports, {
  default: () => antdImports_default
});
var antdImports_default = {
  "antd/es/affix": require("antd/es/affix"),
  "antd/es/affix/style": require("antd/es/affix/style"),
  "antd/es/affix/utils": require("antd/es/affix/utils"),
  "antd/es/alert/Alert": require("antd/es/alert/Alert"),
  "antd/es/alert/ErrorBoundary": require("antd/es/alert/ErrorBoundary"),
  "antd/es/alert": require("antd/es/alert"),
  "antd/es/alert/style": require("antd/es/alert/style"),
  "antd/es/anchor/Anchor": require("antd/es/anchor/Anchor"),
  "antd/es/anchor/AnchorLink": require("antd/es/anchor/AnchorLink"),
  "antd/es/anchor/context": require("antd/es/anchor/context"),
  "antd/es/anchor": require("antd/es/anchor"),
  "antd/es/anchor/style": require("antd/es/anchor/style"),
  "antd/es/app/context": require("antd/es/app/context"),
  "antd/es/app": require("antd/es/app"),
  "antd/es/app/style": require("antd/es/app/style"),
  "antd/es/auto-complete": require("antd/es/auto-complete"),
  "antd/es/avatar/AvatarContext": require("antd/es/avatar/AvatarContext"),
  "antd/es/avatar/avatar": require("antd/es/avatar/avatar"),
  "antd/es/avatar/group": require("antd/es/avatar/group"),
  "antd/es/avatar": require("antd/es/avatar"),
  "antd/es/avatar/style": require("antd/es/avatar/style"),
  "antd/es/back-top": require("antd/es/back-top"),
  "antd/es/back-top/style": require("antd/es/back-top/style"),
  "antd/es/badge/Ribbon": require("antd/es/badge/Ribbon"),
  "antd/es/badge/ScrollNumber": require("antd/es/badge/ScrollNumber"),
  "antd/es/badge/SingleNumber": require("antd/es/badge/SingleNumber"),
  "antd/es/badge": require("antd/es/badge"),
  "antd/es/badge/style": require("antd/es/badge/style"),
  "antd/es/badge/style/ribbon": require("antd/es/badge/style/ribbon"),
  "antd/es/breadcrumb/Breadcrumb": require("antd/es/breadcrumb/Breadcrumb"),
  "antd/es/breadcrumb/BreadcrumbItem": require("antd/es/breadcrumb/BreadcrumbItem"),
  "antd/es/breadcrumb/BreadcrumbSeparator": require("antd/es/breadcrumb/BreadcrumbSeparator"),
  "antd/es/breadcrumb": require("antd/es/breadcrumb"),
  "antd/es/breadcrumb/style": require("antd/es/breadcrumb/style"),
  "antd/es/breadcrumb/useItemRender": require("antd/es/breadcrumb/useItemRender"),
  "antd/es/breadcrumb/useItems": require("antd/es/breadcrumb/useItems"),
  "antd/es/button/IconWrapper": require("antd/es/button/IconWrapper"),
  "antd/es/button/LoadingIcon": require("antd/es/button/LoadingIcon"),
  "antd/es/button/button-group": require("antd/es/button/button-group"),
  "antd/es/button/button": require("antd/es/button/button"),
  "antd/es/button/buttonHelpers": require("antd/es/button/buttonHelpers"),
  "antd/es/button": require("antd/es/button"),
  "antd/es/button/style/compactCmp": require("antd/es/button/style/compactCmp"),
  "antd/es/button/style/group": require("antd/es/button/style/group"),
  "antd/es/button/style": require("antd/es/button/style"),
  "antd/es/button/style/token": require("antd/es/button/style/token"),
  "antd/es/calendar/Header": require("antd/es/calendar/Header"),
  "antd/es/calendar/generateCalendar": require("antd/es/calendar/generateCalendar"),
  "antd/es/calendar": require("antd/es/calendar"),
  "antd/es/calendar/locale/ar_EG": require("antd/es/calendar/locale/ar_EG"),
  "antd/es/calendar/locale/az_AZ": require("antd/es/calendar/locale/az_AZ"),
  "antd/es/calendar/locale/bg_BG": require("antd/es/calendar/locale/bg_BG"),
  "antd/es/calendar/locale/bn_BD": require("antd/es/calendar/locale/bn_BD"),
  "antd/es/calendar/locale/by_BY": require("antd/es/calendar/locale/by_BY"),
  "antd/es/calendar/locale/ca_ES": require("antd/es/calendar/locale/ca_ES"),
  "antd/es/calendar/locale/cs_CZ": require("antd/es/calendar/locale/cs_CZ"),
  "antd/es/calendar/locale/da_DK": require("antd/es/calendar/locale/da_DK"),
  "antd/es/calendar/locale/de_DE": require("antd/es/calendar/locale/de_DE"),
  "antd/es/calendar/locale/el_GR": require("antd/es/calendar/locale/el_GR"),
  "antd/es/calendar/locale/en_GB": require("antd/es/calendar/locale/en_GB"),
  "antd/es/calendar/locale/en_US": require("antd/es/calendar/locale/en_US"),
  "antd/es/calendar/locale/es_ES": require("antd/es/calendar/locale/es_ES"),
  "antd/es/calendar/locale/et_EE": require("antd/es/calendar/locale/et_EE"),
  "antd/es/calendar/locale/eu_ES": require("antd/es/calendar/locale/eu_ES"),
  "antd/es/calendar/locale/fa_IR": require("antd/es/calendar/locale/fa_IR"),
  "antd/es/calendar/locale/fi_FI": require("antd/es/calendar/locale/fi_FI"),
  "antd/es/calendar/locale/fr_BE": require("antd/es/calendar/locale/fr_BE"),
  "antd/es/calendar/locale/fr_CA": require("antd/es/calendar/locale/fr_CA"),
  "antd/es/calendar/locale/fr_FR": require("antd/es/calendar/locale/fr_FR"),
  "antd/es/calendar/locale/ga_IE": require("antd/es/calendar/locale/ga_IE"),
  "antd/es/calendar/locale/gl_ES": require("antd/es/calendar/locale/gl_ES"),
  "antd/es/calendar/locale/he_IL": require("antd/es/calendar/locale/he_IL"),
  "antd/es/calendar/locale/hi_IN": require("antd/es/calendar/locale/hi_IN"),
  "antd/es/calendar/locale/hr_HR": require("antd/es/calendar/locale/hr_HR"),
  "antd/es/calendar/locale/hu_HU": require("antd/es/calendar/locale/hu_HU"),
  "antd/es/calendar/locale/id_ID": require("antd/es/calendar/locale/id_ID"),
  "antd/es/calendar/locale/is_IS": require("antd/es/calendar/locale/is_IS"),
  "antd/es/calendar/locale/it_IT": require("antd/es/calendar/locale/it_IT"),
  "antd/es/calendar/locale/ja_JP": require("antd/es/calendar/locale/ja_JP"),
  "antd/es/calendar/locale/ka_GE": require("antd/es/calendar/locale/ka_GE"),
  "antd/es/calendar/locale/kk_KZ": require("antd/es/calendar/locale/kk_KZ"),
  "antd/es/calendar/locale/km_KH": require("antd/es/calendar/locale/km_KH"),
  "antd/es/calendar/locale/kmr_IQ": require("antd/es/calendar/locale/kmr_IQ"),
  "antd/es/calendar/locale/kn_IN": require("antd/es/calendar/locale/kn_IN"),
  "antd/es/calendar/locale/ko_KR": require("antd/es/calendar/locale/ko_KR"),
  "antd/es/calendar/locale/lt_LT": require("antd/es/calendar/locale/lt_LT"),
  "antd/es/calendar/locale/lv_LV": require("antd/es/calendar/locale/lv_LV"),
  "antd/es/calendar/locale/mk_MK": require("antd/es/calendar/locale/mk_MK"),
  "antd/es/calendar/locale/ml_IN": require("antd/es/calendar/locale/ml_IN"),
  "antd/es/calendar/locale/mn_MN": require("antd/es/calendar/locale/mn_MN"),
  "antd/es/calendar/locale/ms_MY": require("antd/es/calendar/locale/ms_MY"),
  "antd/es/calendar/locale/my_MM": require("antd/es/calendar/locale/my_MM"),
  "antd/es/calendar/locale/nb_NO": require("antd/es/calendar/locale/nb_NO"),
  "antd/es/calendar/locale/nl_BE": require("antd/es/calendar/locale/nl_BE"),
  "antd/es/calendar/locale/nl_NL": require("antd/es/calendar/locale/nl_NL"),
  "antd/es/calendar/locale/pl_PL": require("antd/es/calendar/locale/pl_PL"),
  "antd/es/calendar/locale/pt_BR": require("antd/es/calendar/locale/pt_BR"),
  "antd/es/calendar/locale/pt_PT": require("antd/es/calendar/locale/pt_PT"),
  "antd/es/calendar/locale/ro_RO": require("antd/es/calendar/locale/ro_RO"),
  "antd/es/calendar/locale/ru_RU": require("antd/es/calendar/locale/ru_RU"),
  "antd/es/calendar/locale/si_LK": require("antd/es/calendar/locale/si_LK"),
  "antd/es/calendar/locale/sk_SK": require("antd/es/calendar/locale/sk_SK"),
  "antd/es/calendar/locale/sl_SI": require("antd/es/calendar/locale/sl_SI"),
  "antd/es/calendar/locale/sr_RS": require("antd/es/calendar/locale/sr_RS"),
  "antd/es/calendar/locale/sv_SE": require("antd/es/calendar/locale/sv_SE"),
  "antd/es/calendar/locale/ta_IN": require("antd/es/calendar/locale/ta_IN"),
  "antd/es/calendar/locale/th_TH": require("antd/es/calendar/locale/th_TH"),
  "antd/es/calendar/locale/tk_TK": require("antd/es/calendar/locale/tk_TK"),
  "antd/es/calendar/locale/tr_TR": require("antd/es/calendar/locale/tr_TR"),
  "antd/es/calendar/locale/uk_UA": require("antd/es/calendar/locale/uk_UA"),
  "antd/es/calendar/locale/ur_PK": require("antd/es/calendar/locale/ur_PK"),
  "antd/es/calendar/locale/uz_UZ": require("antd/es/calendar/locale/uz_UZ"),
  "antd/es/calendar/locale/vi_VN": require("antd/es/calendar/locale/vi_VN"),
  "antd/es/calendar/locale/zh_CN": require("antd/es/calendar/locale/zh_CN"),
  "antd/es/calendar/locale/zh_TW": require("antd/es/calendar/locale/zh_TW"),
  "antd/es/calendar/style": require("antd/es/calendar/style"),
  "antd/es/card/Card": require("antd/es/card/Card"),
  "antd/es/card/Grid": require("antd/es/card/Grid"),
  "antd/es/card/Meta": require("antd/es/card/Meta"),
  "antd/es/card": require("antd/es/card"),
  "antd/es/card/style": require("antd/es/card/style"),
  "antd/es/carousel": require("antd/es/carousel"),
  "antd/es/carousel/style": require("antd/es/carousel/style"),
  "antd/es/cascader/Panel": require("antd/es/cascader/Panel"),
  "antd/es/cascader/hooks/useBase": require("antd/es/cascader/hooks/useBase"),
  "antd/es/cascader/hooks/useCheckable": require("antd/es/cascader/hooks/useCheckable"),
  "antd/es/cascader/hooks/useColumnIcons": require("antd/es/cascader/hooks/useColumnIcons"),
  "antd/es/cascader": require("antd/es/cascader"),
  "antd/es/cascader/style/columns": require("antd/es/cascader/style/columns"),
  "antd/es/cascader/style": require("antd/es/cascader/style"),
  "antd/es/cascader/style/panel": require("antd/es/cascader/style/panel"),
  "antd/es/checkbox/Checkbox": require("antd/es/checkbox/Checkbox"),
  "antd/es/checkbox/Group": require("antd/es/checkbox/Group"),
  "antd/es/checkbox/GroupContext": require("antd/es/checkbox/GroupContext"),
  "antd/es/checkbox": require("antd/es/checkbox"),
  "antd/es/checkbox/style": require("antd/es/checkbox/style"),
  "antd/es/col": require("antd/es/col"),
  "antd/es/col/style": require("antd/es/col/style"),
  "antd/es/collapse/Collapse": require("antd/es/collapse/Collapse"),
  "antd/es/collapse/CollapsePanel": require("antd/es/collapse/CollapsePanel"),
  "antd/es/collapse": require("antd/es/collapse"),
  "antd/es/collapse/style": require("antd/es/collapse/style"),
  "antd/es/color-picker/ColorPicker": require("antd/es/color-picker/ColorPicker"),
  "antd/es/color-picker/ColorPickerPanel": require("antd/es/color-picker/ColorPickerPanel"),
  "antd/es/color-picker/color": require("antd/es/color-picker/color"),
  "antd/es/color-picker/components/ColorAlphaInput": require("antd/es/color-picker/components/ColorAlphaInput"),
  "antd/es/color-picker/components/ColorClear": require("antd/es/color-picker/components/ColorClear"),
  "antd/es/color-picker/components/ColorHexInput": require("antd/es/color-picker/components/ColorHexInput"),
  "antd/es/color-picker/components/ColorHsbInput": require("antd/es/color-picker/components/ColorHsbInput"),
  "antd/es/color-picker/components/ColorInput": require("antd/es/color-picker/components/ColorInput"),
  "antd/es/color-picker/components/ColorPresets": require("antd/es/color-picker/components/ColorPresets"),
  "antd/es/color-picker/components/ColorRgbInput": require("antd/es/color-picker/components/ColorRgbInput"),
  "antd/es/color-picker/components/ColorSteppers": require("antd/es/color-picker/components/ColorSteppers"),
  "antd/es/color-picker/components/ColorTrigger": require("antd/es/color-picker/components/ColorTrigger"),
  "antd/es/color-picker/components/PanelPicker": require("antd/es/color-picker/components/PanelPicker"),
  "antd/es/color-picker/components/PanelPresets": require("antd/es/color-picker/components/PanelPresets"),
  "antd/es/color-picker/context": require("antd/es/color-picker/context"),
  "antd/es/color-picker/hooks/useColorState": require("antd/es/color-picker/hooks/useColorState"),
  "antd/es/color-picker": require("antd/es/color-picker"),
  "antd/es/color-picker/interface": require("antd/es/color-picker/interface"),
  "antd/es/color-picker/style/color-block": require("antd/es/color-picker/style/color-block"),
  "antd/es/color-picker/style": require("antd/es/color-picker/style"),
  "antd/es/color-picker/style/input": require("antd/es/color-picker/style/input"),
  "antd/es/color-picker/style/picker": require("antd/es/color-picker/style/picker"),
  "antd/es/color-picker/style/presets": require("antd/es/color-picker/style/presets"),
  "antd/es/color-picker/util": require("antd/es/color-picker/util"),
  "antd/es/config-provider/DisabledContext": require("antd/es/config-provider/DisabledContext"),
  "antd/es/config-provider/MotionWrapper": require("antd/es/config-provider/MotionWrapper"),
  "antd/es/config-provider/PropWarning": require("antd/es/config-provider/PropWarning"),
  "antd/es/config-provider/SizeContext": require("antd/es/config-provider/SizeContext"),
  "antd/es/config-provider/context": require("antd/es/config-provider/context"),
  "antd/es/config-provider/cssVariables": require("antd/es/config-provider/cssVariables"),
  "antd/es/config-provider/defaultRenderEmpty": require("antd/es/config-provider/defaultRenderEmpty"),
  "antd/es/config-provider/hooks/useCSSVarCls": require("antd/es/config-provider/hooks/useCSSVarCls"),
  "antd/es/config-provider/hooks/useConfig": require("antd/es/config-provider/hooks/useConfig"),
  "antd/es/config-provider/hooks/useSize": require("antd/es/config-provider/hooks/useSize"),
  "antd/es/config-provider/hooks/useTheme": require("antd/es/config-provider/hooks/useTheme"),
  "antd/es/config-provider/hooks/useThemeKey": require("antd/es/config-provider/hooks/useThemeKey"),
  "antd/es/config-provider": require("antd/es/config-provider"),
  "antd/es/config-provider/style": require("antd/es/config-provider/style"),
  "antd/es/date-picker/PickerButton": require("antd/es/date-picker/PickerButton"),
  "antd/es/date-picker/generatePicker/generateRangePicker": require("antd/es/date-picker/generatePicker/generateRangePicker"),
  "antd/es/date-picker/generatePicker/generateSinglePicker": require("antd/es/date-picker/generatePicker/generateSinglePicker"),
  "antd/es/date-picker/generatePicker": require("antd/es/date-picker/generatePicker"),
  "antd/es/date-picker/generatePicker/interface": require("antd/es/date-picker/generatePicker/interface"),
  "antd/es/date-picker/generatePicker/useComponents": require("antd/es/date-picker/generatePicker/useComponents"),
  "antd/es/date-picker": require("antd/es/date-picker"),
  "antd/es/date-picker/locale/ar_EG": require("antd/es/date-picker/locale/ar_EG"),
  "antd/es/date-picker/locale/az_AZ": require("antd/es/date-picker/locale/az_AZ"),
  "antd/es/date-picker/locale/bg_BG": require("antd/es/date-picker/locale/bg_BG"),
  "antd/es/date-picker/locale/bn_BD": require("antd/es/date-picker/locale/bn_BD"),
  "antd/es/date-picker/locale/by_BY": require("antd/es/date-picker/locale/by_BY"),
  "antd/es/date-picker/locale/ca_ES": require("antd/es/date-picker/locale/ca_ES"),
  "antd/es/date-picker/locale/cs_CZ": require("antd/es/date-picker/locale/cs_CZ"),
  "antd/es/date-picker/locale/da_DK": require("antd/es/date-picker/locale/da_DK"),
  "antd/es/date-picker/locale/de_DE": require("antd/es/date-picker/locale/de_DE"),
  "antd/es/date-picker/locale/el_GR": require("antd/es/date-picker/locale/el_GR"),
  "antd/es/date-picker/locale/en_GB": require("antd/es/date-picker/locale/en_GB"),
  "antd/es/date-picker/locale/en_US": require("antd/es/date-picker/locale/en_US"),
  "antd/es/date-picker/locale/es_ES": require("antd/es/date-picker/locale/es_ES"),
  "antd/es/date-picker/locale/et_EE": require("antd/es/date-picker/locale/et_EE"),
  "antd/es/date-picker/locale/eu_ES": require("antd/es/date-picker/locale/eu_ES"),
  "antd/es/date-picker/locale/fa_IR": require("antd/es/date-picker/locale/fa_IR"),
  "antd/es/date-picker/locale/fi_FI": require("antd/es/date-picker/locale/fi_FI"),
  "antd/es/date-picker/locale/fr_BE": require("antd/es/date-picker/locale/fr_BE"),
  "antd/es/date-picker/locale/fr_CA": require("antd/es/date-picker/locale/fr_CA"),
  "antd/es/date-picker/locale/fr_FR": require("antd/es/date-picker/locale/fr_FR"),
  "antd/es/date-picker/locale/ga_IE": require("antd/es/date-picker/locale/ga_IE"),
  "antd/es/date-picker/locale/gl_ES": require("antd/es/date-picker/locale/gl_ES"),
  "antd/es/date-picker/locale/he_IL": require("antd/es/date-picker/locale/he_IL"),
  "antd/es/date-picker/locale/hi_IN": require("antd/es/date-picker/locale/hi_IN"),
  "antd/es/date-picker/locale/hr_HR": require("antd/es/date-picker/locale/hr_HR"),
  "antd/es/date-picker/locale/hu_HU": require("antd/es/date-picker/locale/hu_HU"),
  "antd/es/date-picker/locale/id_ID": require("antd/es/date-picker/locale/id_ID"),
  "antd/es/date-picker/locale/is_IS": require("antd/es/date-picker/locale/is_IS"),
  "antd/es/date-picker/locale/it_IT": require("antd/es/date-picker/locale/it_IT"),
  "antd/es/date-picker/locale/ja_JP": require("antd/es/date-picker/locale/ja_JP"),
  "antd/es/date-picker/locale/ka_GE": require("antd/es/date-picker/locale/ka_GE"),
  "antd/es/date-picker/locale/kk_KZ": require("antd/es/date-picker/locale/kk_KZ"),
  "antd/es/date-picker/locale/km_KH": require("antd/es/date-picker/locale/km_KH"),
  "antd/es/date-picker/locale/kmr_IQ": require("antd/es/date-picker/locale/kmr_IQ"),
  "antd/es/date-picker/locale/kn_IN": require("antd/es/date-picker/locale/kn_IN"),
  "antd/es/date-picker/locale/ko_KR": require("antd/es/date-picker/locale/ko_KR"),
  "antd/es/date-picker/locale/lt_LT": require("antd/es/date-picker/locale/lt_LT"),
  "antd/es/date-picker/locale/lv_LV": require("antd/es/date-picker/locale/lv_LV"),
  "antd/es/date-picker/locale/mk_MK": require("antd/es/date-picker/locale/mk_MK"),
  "antd/es/date-picker/locale/ml_IN": require("antd/es/date-picker/locale/ml_IN"),
  "antd/es/date-picker/locale/mn_MN": require("antd/es/date-picker/locale/mn_MN"),
  "antd/es/date-picker/locale/ms_MY": require("antd/es/date-picker/locale/ms_MY"),
  "antd/es/date-picker/locale/my_MM": require("antd/es/date-picker/locale/my_MM"),
  "antd/es/date-picker/locale/nb_NO": require("antd/es/date-picker/locale/nb_NO"),
  "antd/es/date-picker/locale/nl_BE": require("antd/es/date-picker/locale/nl_BE"),
  "antd/es/date-picker/locale/nl_NL": require("antd/es/date-picker/locale/nl_NL"),
  "antd/es/date-picker/locale/pl_PL": require("antd/es/date-picker/locale/pl_PL"),
  "antd/es/date-picker/locale/pt_BR": require("antd/es/date-picker/locale/pt_BR"),
  "antd/es/date-picker/locale/pt_PT": require("antd/es/date-picker/locale/pt_PT"),
  "antd/es/date-picker/locale/ro_RO": require("antd/es/date-picker/locale/ro_RO"),
  "antd/es/date-picker/locale/ru_RU": require("antd/es/date-picker/locale/ru_RU"),
  "antd/es/date-picker/locale/si_LK": require("antd/es/date-picker/locale/si_LK"),
  "antd/es/date-picker/locale/sk_SK": require("antd/es/date-picker/locale/sk_SK"),
  "antd/es/date-picker/locale/sl_SI": require("antd/es/date-picker/locale/sl_SI"),
  "antd/es/date-picker/locale/sr_RS": require("antd/es/date-picker/locale/sr_RS"),
  "antd/es/date-picker/locale/sv_SE": require("antd/es/date-picker/locale/sv_SE"),
  "antd/es/date-picker/locale/ta_IN": require("antd/es/date-picker/locale/ta_IN"),
  "antd/es/date-picker/locale/th_TH": require("antd/es/date-picker/locale/th_TH"),
  "antd/es/date-picker/locale/tk_TK": require("antd/es/date-picker/locale/tk_TK"),
  "antd/es/date-picker/locale/tr_TR": require("antd/es/date-picker/locale/tr_TR"),
  "antd/es/date-picker/locale/uk_UA": require("antd/es/date-picker/locale/uk_UA"),
  "antd/es/date-picker/locale/ur_PK": require("antd/es/date-picker/locale/ur_PK"),
  "antd/es/date-picker/locale/uz_UZ": require("antd/es/date-picker/locale/uz_UZ"),
  "antd/es/date-picker/locale/vi_VN": require("antd/es/date-picker/locale/vi_VN"),
  "antd/es/date-picker/locale/zh_CN": require("antd/es/date-picker/locale/zh_CN"),
  "antd/es/date-picker/locale/zh_TW": require("antd/es/date-picker/locale/zh_TW"),
  "antd/es/date-picker/style": require("antd/es/date-picker/style"),
  "antd/es/date-picker/style/multiple": require("antd/es/date-picker/style/multiple"),
  "antd/es/date-picker/style/panel": require("antd/es/date-picker/style/panel"),
  "antd/es/date-picker/style/token": require("antd/es/date-picker/style/token"),
  "antd/es/date-picker/style/variants": require("antd/es/date-picker/style/variants"),
  "antd/es/date-picker/util": require("antd/es/date-picker/util"),
  "antd/es/descriptions/Cell": require("antd/es/descriptions/Cell"),
  "antd/es/descriptions/DescriptionsContext": require("antd/es/descriptions/DescriptionsContext"),
  "antd/es/descriptions/Item": require("antd/es/descriptions/Item"),
  "antd/es/descriptions/Row": require("antd/es/descriptions/Row"),
  "antd/es/descriptions/constant": require("antd/es/descriptions/constant"),
  "antd/es/descriptions/hooks/useItems": require("antd/es/descriptions/hooks/useItems"),
  "antd/es/descriptions/hooks/useRow": require("antd/es/descriptions/hooks/useRow"),
  "antd/es/descriptions": require("antd/es/descriptions"),
  "antd/es/descriptions/style": require("antd/es/descriptions/style"),
  "antd/es/divider": require("antd/es/divider"),
  "antd/es/divider/style": require("antd/es/divider/style"),
  "antd/es/drawer/DrawerPanel": require("antd/es/drawer/DrawerPanel"),
  "antd/es/drawer": require("antd/es/drawer"),
  "antd/es/drawer/style": require("antd/es/drawer/style"),
  "antd/es/drawer/style/motion": require("antd/es/drawer/style/motion"),
  "antd/es/dropdown/dropdown-button": require("antd/es/dropdown/dropdown-button"),
  "antd/es/dropdown/dropdown": require("antd/es/dropdown/dropdown"),
  "antd/es/dropdown": require("antd/es/dropdown"),
  "antd/es/dropdown/style": require("antd/es/dropdown/style"),
  "antd/es/dropdown/style/status": require("antd/es/dropdown/style/status"),
  "antd/es/empty/empty": require("antd/es/empty/empty"),
  "antd/es/empty": require("antd/es/empty"),
  "antd/es/empty/simple": require("antd/es/empty/simple"),
  "antd/es/empty/style": require("antd/es/empty/style"),
  "antd/es/flex": require("antd/es/flex"),
  "antd/es/flex/interface": require("antd/es/flex/interface"),
  "antd/es/flex/style": require("antd/es/flex/style"),
  "antd/es/flex/utils": require("antd/es/flex/utils"),
  "antd/es/float-button/BackTop": require("antd/es/float-button/BackTop"),
  "antd/es/float-button/FloatButton": require("antd/es/float-button/FloatButton"),
  "antd/es/float-button/FloatButtonContent": require("antd/es/float-button/FloatButtonContent"),
  "antd/es/float-button/FloatButtonGroup": require("antd/es/float-button/FloatButtonGroup"),
  "antd/es/float-button/PurePanel": require("antd/es/float-button/PurePanel"),
  "antd/es/float-button/context": require("antd/es/float-button/context"),
  "antd/es/float-button": require("antd/es/float-button"),
  "antd/es/float-button/interface": require("antd/es/float-button/interface"),
  "antd/es/float-button/style": require("antd/es/float-button/style"),
  "antd/es/float-button/util": require("antd/es/float-button/util"),
  "antd/es/form/ErrorList": require("antd/es/form/ErrorList"),
  "antd/es/form/Form": require("antd/es/form/Form"),
  "antd/es/form/FormItem/ItemHolder": require("antd/es/form/FormItem/ItemHolder"),
  "antd/es/form/FormItem/StatusProvider": require("antd/es/form/FormItem/StatusProvider"),
  "antd/es/form/FormItem": require("antd/es/form/FormItem"),
  "antd/es/form/FormItemInput": require("antd/es/form/FormItemInput"),
  "antd/es/form/FormItemLabel": require("antd/es/form/FormItemLabel"),
  "antd/es/form/FormList": require("antd/es/form/FormList"),
  "antd/es/form/context": require("antd/es/form/context"),
  "antd/es/form/hooks/useChildren": require("antd/es/form/hooks/useChildren"),
  "antd/es/form/hooks/useDebounce": require("antd/es/form/hooks/useDebounce"),
  "antd/es/form/hooks/useForm": require("antd/es/form/hooks/useForm"),
  "antd/es/form/hooks/useFormInstance": require("antd/es/form/hooks/useFormInstance"),
  "antd/es/form/hooks/useFormItemStatus": require("antd/es/form/hooks/useFormItemStatus"),
  "antd/es/form/hooks/useFormWarning": require("antd/es/form/hooks/useFormWarning"),
  "antd/es/form/hooks/useFrameState": require("antd/es/form/hooks/useFrameState"),
  "antd/es/form/hooks/useItemRef": require("antd/es/form/hooks/useItemRef"),
  "antd/es/form/hooks/useVariants": require("antd/es/form/hooks/useVariants"),
  "antd/es/form": require("antd/es/form"),
  "antd/es/form/interface": require("antd/es/form/interface"),
  "antd/es/form/style/explain": require("antd/es/form/style/explain"),
  "antd/es/form/style/fallbackCmp": require("antd/es/form/style/fallbackCmp"),
  "antd/es/form/style": require("antd/es/form/style"),
  "antd/es/form/util": require("antd/es/form/util"),
  "antd/es/form/validateMessagesContext": require("antd/es/form/validateMessagesContext"),
  "antd/es/grid/RowContext": require("antd/es/grid/RowContext"),
  "antd/es/grid/col": require("antd/es/grid/col"),
  "antd/es/grid/hooks/useBreakpoint": require("antd/es/grid/hooks/useBreakpoint"),
  "antd/es/grid": require("antd/es/grid"),
  "antd/es/grid/row": require("antd/es/grid/row"),
  "antd/es/grid/style": require("antd/es/grid/style"),
  "antd/es/icon": require("antd/es/icon"),
  "antd/es/image/PreviewGroup": require("antd/es/image/PreviewGroup"),
  "antd/es/image": require("antd/es/image"),
  "antd/es/image/style": require("antd/es/image/style"),
  "antd/es": require("antd/es"),
  "antd/es/input/Group": require("antd/es/input/Group"),
  "antd/es/input/Input": require("antd/es/input/Input"),
  "antd/es/input/OTP/OTPInput": require("antd/es/input/OTP/OTPInput"),
  "antd/es/input/OTP": require("antd/es/input/OTP"),
  "antd/es/input/Password": require("antd/es/input/Password"),
  "antd/es/input/Search": require("antd/es/input/Search"),
  "antd/es/input/TextArea": require("antd/es/input/TextArea"),
  "antd/es/input/hooks/useRemovePasswordTimeout": require("antd/es/input/hooks/useRemovePasswordTimeout"),
  "antd/es/input": require("antd/es/input"),
  "antd/es/input/style": require("antd/es/input/style"),
  "antd/es/input/style/otp": require("antd/es/input/style/otp"),
  "antd/es/input/style/token": require("antd/es/input/style/token"),
  "antd/es/input/style/variants": require("antd/es/input/style/variants"),
  "antd/es/input/utils": require("antd/es/input/utils"),
  "antd/es/input-number": require("antd/es/input-number"),
  "antd/es/input-number/style": require("antd/es/input-number/style"),
  "antd/es/input-number/style/token": require("antd/es/input-number/style/token"),
  "antd/es/layout/Sider": require("antd/es/layout/Sider"),
  "antd/es/layout/context": require("antd/es/layout/context"),
  "antd/es/layout/hooks/useHasSider": require("antd/es/layout/hooks/useHasSider"),
  "antd/es/layout": require("antd/es/layout"),
  "antd/es/layout/layout": require("antd/es/layout/layout"),
  "antd/es/layout/style": require("antd/es/layout/style"),
  "antd/es/layout/style/light": require("antd/es/layout/style/light"),
  "antd/es/list/Item": require("antd/es/list/Item"),
  "antd/es/list/context": require("antd/es/list/context"),
  "antd/es/list": require("antd/es/list"),
  "antd/es/list/style": require("antd/es/list/style"),
  "antd/es/locale/ar_EG": require("antd/es/locale/ar_EG"),
  "antd/es/locale/az_AZ": require("antd/es/locale/az_AZ"),
  "antd/es/locale/bg_BG": require("antd/es/locale/bg_BG"),
  "antd/es/locale/bn_BD": require("antd/es/locale/bn_BD"),
  "antd/es/locale/by_BY": require("antd/es/locale/by_BY"),
  "antd/es/locale/ca_ES": require("antd/es/locale/ca_ES"),
  "antd/es/locale/context": require("antd/es/locale/context"),
  "antd/es/locale/cs_CZ": require("antd/es/locale/cs_CZ"),
  "antd/es/locale/da_DK": require("antd/es/locale/da_DK"),
  "antd/es/locale/de_DE": require("antd/es/locale/de_DE"),
  "antd/es/locale/el_GR": require("antd/es/locale/el_GR"),
  "antd/es/locale/en_GB": require("antd/es/locale/en_GB"),
  "antd/es/locale/en_US": require("antd/es/locale/en_US"),
  "antd/es/locale/es_ES": require("antd/es/locale/es_ES"),
  "antd/es/locale/et_EE": require("antd/es/locale/et_EE"),
  "antd/es/locale/eu_ES": require("antd/es/locale/eu_ES"),
  "antd/es/locale/fa_IR": require("antd/es/locale/fa_IR"),
  "antd/es/locale/fi_FI": require("antd/es/locale/fi_FI"),
  "antd/es/locale/fr_BE": require("antd/es/locale/fr_BE"),
  "antd/es/locale/fr_CA": require("antd/es/locale/fr_CA"),
  "antd/es/locale/fr_FR": require("antd/es/locale/fr_FR"),
  "antd/es/locale/ga_IE": require("antd/es/locale/ga_IE"),
  "antd/es/locale/gl_ES": require("antd/es/locale/gl_ES"),
  "antd/es/locale/he_IL": require("antd/es/locale/he_IL"),
  "antd/es/locale/hi_IN": require("antd/es/locale/hi_IN"),
  "antd/es/locale/hr_HR": require("antd/es/locale/hr_HR"),
  "antd/es/locale/hu_HU": require("antd/es/locale/hu_HU"),
  "antd/es/locale/hy_AM": require("antd/es/locale/hy_AM"),
  "antd/es/locale/id_ID": require("antd/es/locale/id_ID"),
  "antd/es/locale": require("antd/es/locale"),
  "antd/es/locale/is_IS": require("antd/es/locale/is_IS"),
  "antd/es/locale/it_IT": require("antd/es/locale/it_IT"),
  "antd/es/locale/ja_JP": require("antd/es/locale/ja_JP"),
  "antd/es/locale/ka_GE": require("antd/es/locale/ka_GE"),
  "antd/es/locale/kk_KZ": require("antd/es/locale/kk_KZ"),
  "antd/es/locale/km_KH": require("antd/es/locale/km_KH"),
  "antd/es/locale/kmr_IQ": require("antd/es/locale/kmr_IQ"),
  "antd/es/locale/kn_IN": require("antd/es/locale/kn_IN"),
  "antd/es/locale/ko_KR": require("antd/es/locale/ko_KR"),
  "antd/es/locale/ku_IQ": require("antd/es/locale/ku_IQ"),
  "antd/es/locale/lt_LT": require("antd/es/locale/lt_LT"),
  "antd/es/locale/lv_LV": require("antd/es/locale/lv_LV"),
  "antd/es/locale/mk_MK": require("antd/es/locale/mk_MK"),
  "antd/es/locale/ml_IN": require("antd/es/locale/ml_IN"),
  "antd/es/locale/mn_MN": require("antd/es/locale/mn_MN"),
  "antd/es/locale/ms_MY": require("antd/es/locale/ms_MY"),
  "antd/es/locale/my_MM": require("antd/es/locale/my_MM"),
  "antd/es/locale/nb_NO": require("antd/es/locale/nb_NO"),
  "antd/es/locale/ne_NP": require("antd/es/locale/ne_NP"),
  "antd/es/locale/nl_BE": require("antd/es/locale/nl_BE"),
  "antd/es/locale/nl_NL": require("antd/es/locale/nl_NL"),
  "antd/es/locale/pl_PL": require("antd/es/locale/pl_PL"),
  "antd/es/locale/pt_BR": require("antd/es/locale/pt_BR"),
  "antd/es/locale/pt_PT": require("antd/es/locale/pt_PT"),
  "antd/es/locale/ro_RO": require("antd/es/locale/ro_RO"),
  "antd/es/locale/ru_RU": require("antd/es/locale/ru_RU"),
  "antd/es/locale/si_LK": require("antd/es/locale/si_LK"),
  "antd/es/locale/sk_SK": require("antd/es/locale/sk_SK"),
  "antd/es/locale/sl_SI": require("antd/es/locale/sl_SI"),
  "antd/es/locale/sr_RS": require("antd/es/locale/sr_RS"),
  "antd/es/locale/sv_SE": require("antd/es/locale/sv_SE"),
  "antd/es/locale/ta_IN": require("antd/es/locale/ta_IN"),
  "antd/es/locale/th_TH": require("antd/es/locale/th_TH"),
  "antd/es/locale/tk_TK": require("antd/es/locale/tk_TK"),
  "antd/es/locale/tr_TR": require("antd/es/locale/tr_TR"),
  "antd/es/locale/uk_UA": require("antd/es/locale/uk_UA"),
  "antd/es/locale/ur_PK": require("antd/es/locale/ur_PK"),
  "antd/es/locale/useLocale": require("antd/es/locale/useLocale"),
  "antd/es/locale/uz_UZ": require("antd/es/locale/uz_UZ"),
  "antd/es/locale/vi_VN": require("antd/es/locale/vi_VN"),
  "antd/es/locale/zh_CN": require("antd/es/locale/zh_CN"),
  "antd/es/locale/zh_HK": require("antd/es/locale/zh_HK"),
  "antd/es/locale/zh_TW": require("antd/es/locale/zh_TW"),
  "antd/es/mentions": require("antd/es/mentions"),
  "antd/es/mentions/style": require("antd/es/mentions/style"),
  "antd/es/menu/MenuContext": require("antd/es/menu/MenuContext"),
  "antd/es/menu/MenuDivider": require("antd/es/menu/MenuDivider"),
  "antd/es/menu/MenuItem": require("antd/es/menu/MenuItem"),
  "antd/es/menu/OverrideContext": require("antd/es/menu/OverrideContext"),
  "antd/es/menu/SubMenu": require("antd/es/menu/SubMenu"),
  "antd/es/menu": require("antd/es/menu"),
  "antd/es/menu/interface": require("antd/es/menu/interface"),
  "antd/es/menu/menu": require("antd/es/menu/menu"),
  "antd/es/menu/style/horizontal": require("antd/es/menu/style/horizontal"),
  "antd/es/menu/style": require("antd/es/menu/style"),
  "antd/es/menu/style/rtl": require("antd/es/menu/style/rtl"),
  "antd/es/menu/style/theme": require("antd/es/menu/style/theme"),
  "antd/es/menu/style/vertical": require("antd/es/menu/style/vertical"),
  "antd/es/message/PurePanel": require("antd/es/message/PurePanel"),
  "antd/es/message": require("antd/es/message"),
  "antd/es/message/interface": require("antd/es/message/interface"),
  "antd/es/message/style": require("antd/es/message/style"),
  "antd/es/message/useMessage": require("antd/es/message/useMessage"),
  "antd/es/message/util": require("antd/es/message/util"),
  "antd/es/modal/ConfirmDialog": require("antd/es/modal/ConfirmDialog"),
  "antd/es/modal/Modal": require("antd/es/modal/Modal"),
  "antd/es/modal/PurePanel": require("antd/es/modal/PurePanel"),
  "antd/es/modal/components/ConfirmCancelBtn": require("antd/es/modal/components/ConfirmCancelBtn"),
  "antd/es/modal/components/ConfirmOkBtn": require("antd/es/modal/components/ConfirmOkBtn"),
  "antd/es/modal/components/NormalCancelBtn": require("antd/es/modal/components/NormalCancelBtn"),
  "antd/es/modal/components/NormalOkBtn": require("antd/es/modal/components/NormalOkBtn"),
  "antd/es/modal/confirm": require("antd/es/modal/confirm"),
  "antd/es/modal/context": require("antd/es/modal/context"),
  "antd/es/modal/destroyFns": require("antd/es/modal/destroyFns"),
  "antd/es/modal": require("antd/es/modal"),
  "antd/es/modal/interface": require("antd/es/modal/interface"),
  "antd/es/modal/locale": require("antd/es/modal/locale"),
  "antd/es/modal/shared": require("antd/es/modal/shared"),
  "antd/es/modal/style/confirm": require("antd/es/modal/style/confirm"),
  "antd/es/modal/style": require("antd/es/modal/style"),
  "antd/es/modal/useModal/HookModal": require("antd/es/modal/useModal/HookModal"),
  "antd/es/modal/useModal": require("antd/es/modal/useModal"),
  "antd/es/notification/PurePanel": require("antd/es/notification/PurePanel"),
  "antd/es/notification": require("antd/es/notification"),
  "antd/es/notification/interface": require("antd/es/notification/interface"),
  "antd/es/notification/style": require("antd/es/notification/style"),
  "antd/es/notification/style/placement": require("antd/es/notification/style/placement"),
  "antd/es/notification/style/pure-panel": require("antd/es/notification/style/pure-panel"),
  "antd/es/notification/style/stack": require("antd/es/notification/style/stack"),
  "antd/es/notification/useNotification": require("antd/es/notification/useNotification"),
  "antd/es/notification/util": require("antd/es/notification/util"),
  "antd/es/pagination/Pagination": require("antd/es/pagination/Pagination"),
  "antd/es/pagination/Select": require("antd/es/pagination/Select"),
  "antd/es/pagination": require("antd/es/pagination"),
  "antd/es/pagination/style/bordered": require("antd/es/pagination/style/bordered"),
  "antd/es/pagination/style": require("antd/es/pagination/style"),
  "antd/es/popconfirm/PurePanel": require("antd/es/popconfirm/PurePanel"),
  "antd/es/popconfirm": require("antd/es/popconfirm"),
  "antd/es/popconfirm/style": require("antd/es/popconfirm/style"),
  "antd/es/popover/PurePanel": require("antd/es/popover/PurePanel"),
  "antd/es/popover": require("antd/es/popover"),
  "antd/es/popover/style": require("antd/es/popover/style"),
  "antd/es/progress/Circle": require("antd/es/progress/Circle"),
  "antd/es/progress/Line": require("antd/es/progress/Line"),
  "antd/es/progress/Steps": require("antd/es/progress/Steps"),
  "antd/es/progress": require("antd/es/progress"),
  "antd/es/progress/progress": require("antd/es/progress/progress"),
  "antd/es/progress/style": require("antd/es/progress/style"),
  "antd/es/progress/utils": require("antd/es/progress/utils"),
  "antd/es/qr-code": require("antd/es/qr-code"),
  "antd/es/qr-code/interface": require("antd/es/qr-code/interface"),
  "antd/es/qr-code/style": require("antd/es/qr-code/style"),
  "antd/es/qrcode": require("antd/es/qrcode"),
  "antd/es/qrcode/interface": require("antd/es/qrcode/interface"),
  "antd/es/qrcode/style": require("antd/es/qrcode/style"),
  "antd/es/radio/context": require("antd/es/radio/context"),
  "antd/es/radio/group": require("antd/es/radio/group"),
  "antd/es/radio": require("antd/es/radio"),
  "antd/es/radio/interface": require("antd/es/radio/interface"),
  "antd/es/radio/radio": require("antd/es/radio/radio"),
  "antd/es/radio/radioButton": require("antd/es/radio/radioButton"),
  "antd/es/radio/style": require("antd/es/radio/style"),
  "antd/es/rate": require("antd/es/rate"),
  "antd/es/rate/style": require("antd/es/rate/style"),
  "antd/es/result": require("antd/es/result"),
  "antd/es/result/noFound": require("antd/es/result/noFound"),
  "antd/es/result/serverError": require("antd/es/result/serverError"),
  "antd/es/result/style": require("antd/es/result/style"),
  "antd/es/result/unauthorized": require("antd/es/result/unauthorized"),
  "antd/es/row": require("antd/es/row"),
  "antd/es/row/style": require("antd/es/row/style"),
  "antd/es/segmented": require("antd/es/segmented"),
  "antd/es/segmented/style": require("antd/es/segmented/style"),
  "antd/es/select": require("antd/es/select"),
  "antd/es/select/mergedBuiltinPlacements": require("antd/es/select/mergedBuiltinPlacements"),
  "antd/es/select/style/dropdown": require("antd/es/select/style/dropdown"),
  "antd/es/select/style": require("antd/es/select/style"),
  "antd/es/select/style/multiple": require("antd/es/select/style/multiple"),
  "antd/es/select/style/single": require("antd/es/select/style/single"),
  "antd/es/select/style/token": require("antd/es/select/style/token"),
  "antd/es/select/style/variants": require("antd/es/select/style/variants"),
  "antd/es/select/useIcons": require("antd/es/select/useIcons"),
  "antd/es/select/useShowArrow": require("antd/es/select/useShowArrow"),
  "antd/es/skeleton/Avatar": require("antd/es/skeleton/Avatar"),
  "antd/es/skeleton/Button": require("antd/es/skeleton/Button"),
  "antd/es/skeleton/Element": require("antd/es/skeleton/Element"),
  "antd/es/skeleton/Image": require("antd/es/skeleton/Image"),
  "antd/es/skeleton/Input": require("antd/es/skeleton/Input"),
  "antd/es/skeleton/Node": require("antd/es/skeleton/Node"),
  "antd/es/skeleton/Paragraph": require("antd/es/skeleton/Paragraph"),
  "antd/es/skeleton/Skeleton": require("antd/es/skeleton/Skeleton"),
  "antd/es/skeleton/Title": require("antd/es/skeleton/Title"),
  "antd/es/skeleton": require("antd/es/skeleton"),
  "antd/es/skeleton/style": require("antd/es/skeleton/style"),
  "antd/es/slider/SliderTooltip": require("antd/es/slider/SliderTooltip"),
  "antd/es/slider": require("antd/es/slider"),
  "antd/es/slider/style": require("antd/es/slider/style"),
  "antd/es/slider/useRafLock": require("antd/es/slider/useRafLock"),
  "antd/es/space/Compact": require("antd/es/space/Compact"),
  "antd/es/space/Item": require("antd/es/space/Item"),
  "antd/es/space/context": require("antd/es/space/context"),
  "antd/es/space": require("antd/es/space"),
  "antd/es/space/style/compact": require("antd/es/space/style/compact"),
  "antd/es/space/style": require("antd/es/space/style"),
  "antd/es/spin": require("antd/es/spin"),
  "antd/es/spin/style": require("antd/es/spin/style"),
  "antd/es/statistic/Countdown": require("antd/es/statistic/Countdown"),
  "antd/es/statistic/Number": require("antd/es/statistic/Number"),
  "antd/es/statistic/Statistic": require("antd/es/statistic/Statistic"),
  "antd/es/statistic": require("antd/es/statistic"),
  "antd/es/statistic/interface": require("antd/es/statistic/interface"),
  "antd/es/statistic/style": require("antd/es/statistic/style"),
  "antd/es/statistic/utils": require("antd/es/statistic/utils"),
  "antd/es/steps": require("antd/es/steps"),
  "antd/es/steps/style/custom-icon": require("antd/es/steps/style/custom-icon"),
  "antd/es/steps/style/horizontal": require("antd/es/steps/style/horizontal"),
  "antd/es/steps/style": require("antd/es/steps/style"),
  "antd/es/steps/style/inline": require("antd/es/steps/style/inline"),
  "antd/es/steps/style/label-placement": require("antd/es/steps/style/label-placement"),
  "antd/es/steps/style/nav": require("antd/es/steps/style/nav"),
  "antd/es/steps/style/progress-dot": require("antd/es/steps/style/progress-dot"),
  "antd/es/steps/style/progress": require("antd/es/steps/style/progress"),
  "antd/es/steps/style/rtl": require("antd/es/steps/style/rtl"),
  "antd/es/steps/style/small": require("antd/es/steps/style/small"),
  "antd/es/steps/style/vertical": require("antd/es/steps/style/vertical"),
  "antd/es/steps/useLegacyItems": require("antd/es/steps/useLegacyItems"),
  "antd/es/style/compact-item-vertical": require("antd/es/style/compact-item-vertical"),
  "antd/es/style/compact-item": require("antd/es/style/compact-item"),
  "antd/es/style": require("antd/es/style"),
  "antd/es/style/motion/collapse": require("antd/es/style/motion/collapse"),
  "antd/es/style/motion/fade": require("antd/es/style/motion/fade"),
  "antd/es/style/motion": require("antd/es/style/motion"),
  "antd/es/style/motion/motion": require("antd/es/style/motion/motion"),
  "antd/es/style/motion/move": require("antd/es/style/motion/move"),
  "antd/es/style/motion/slide": require("antd/es/style/motion/slide"),
  "antd/es/style/motion/zoom": require("antd/es/style/motion/zoom"),
  "antd/es/style/operationUnit": require("antd/es/style/operationUnit"),
  "antd/es/style/placementArrow": require("antd/es/style/placementArrow"),
  "antd/es/style/roundedArrow": require("antd/es/style/roundedArrow"),
  "antd/es/switch": require("antd/es/switch"),
  "antd/es/switch/style": require("antd/es/switch/style"),
  "antd/es/table/Column": require("antd/es/table/Column"),
  "antd/es/table/ColumnGroup": require("antd/es/table/ColumnGroup"),
  "antd/es/table/ExpandIcon": require("antd/es/table/ExpandIcon"),
  "antd/es/table/InternalTable": require("antd/es/table/InternalTable"),
  "antd/es/table/RcTable/VirtualTable": require("antd/es/table/RcTable/VirtualTable"),
  "antd/es/table/RcTable": require("antd/es/table/RcTable"),
  "antd/es/table/Table": require("antd/es/table/Table"),
  "antd/es/table/hooks/useContainerWidth": require("antd/es/table/hooks/useContainerWidth"),
  "antd/es/table/hooks/useFilter/FilterDropdown": require("antd/es/table/hooks/useFilter/FilterDropdown"),
  "antd/es/table/hooks/useFilter/FilterSearch": require("antd/es/table/hooks/useFilter/FilterSearch"),
  "antd/es/table/hooks/useFilter/FilterWrapper": require("antd/es/table/hooks/useFilter/FilterWrapper"),
  "antd/es/table/hooks/useFilter": require("antd/es/table/hooks/useFilter"),
  "antd/es/table/hooks/useLazyKVMap": require("antd/es/table/hooks/useLazyKVMap"),
  "antd/es/table/hooks/usePagination": require("antd/es/table/hooks/usePagination"),
  "antd/es/table/hooks/useSelection": require("antd/es/table/hooks/useSelection"),
  "antd/es/table/hooks/useSorter": require("antd/es/table/hooks/useSorter"),
  "antd/es/table/hooks/useTitleColumns": require("antd/es/table/hooks/useTitleColumns"),
  "antd/es/table": require("antd/es/table"),
  "antd/es/table/interface": require("antd/es/table/interface"),
  "antd/es/table/style/bordered": require("antd/es/table/style/bordered"),
  "antd/es/table/style/ellipsis": require("antd/es/table/style/ellipsis"),
  "antd/es/table/style/empty": require("antd/es/table/style/empty"),
  "antd/es/table/style/expand": require("antd/es/table/style/expand"),
  "antd/es/table/style/filter": require("antd/es/table/style/filter"),
  "antd/es/table/style/fixed": require("antd/es/table/style/fixed"),
  "antd/es/table/style": require("antd/es/table/style"),
  "antd/es/table/style/pagination": require("antd/es/table/style/pagination"),
  "antd/es/table/style/radius": require("antd/es/table/style/radius"),
  "antd/es/table/style/rtl": require("antd/es/table/style/rtl"),
  "antd/es/table/style/selection": require("antd/es/table/style/selection"),
  "antd/es/table/style/size": require("antd/es/table/style/size"),
  "antd/es/table/style/sorter": require("antd/es/table/style/sorter"),
  "antd/es/table/style/sticky": require("antd/es/table/style/sticky"),
  "antd/es/table/style/summary": require("antd/es/table/style/summary"),
  "antd/es/table/style/virtual": require("antd/es/table/style/virtual"),
  "antd/es/table/util": require("antd/es/table/util"),
  "antd/es/tabs/TabPane": require("antd/es/tabs/TabPane"),
  "antd/es/tabs/hooks/useAnimateConfig": require("antd/es/tabs/hooks/useAnimateConfig"),
  "antd/es/tabs/hooks/useLegacyItems": require("antd/es/tabs/hooks/useLegacyItems"),
  "antd/es/tabs": require("antd/es/tabs"),
  "antd/es/tabs/style": require("antd/es/tabs/style"),
  "antd/es/tabs/style/motion": require("antd/es/tabs/style/motion"),
  "antd/es/tag/CheckableTag": require("antd/es/tag/CheckableTag"),
  "antd/es/tag": require("antd/es/tag"),
  "antd/es/tag/style": require("antd/es/tag/style"),
  "antd/es/tag/style/presetCmp": require("antd/es/tag/style/presetCmp"),
  "antd/es/tag/style/statusCmp": require("antd/es/tag/style/statusCmp"),
  "antd/es/theme/context": require("antd/es/theme/context"),
  "antd/es/theme/getDesignToken": require("antd/es/theme/getDesignToken"),
  "antd/es/theme": require("antd/es/theme"),
  "antd/es/theme/interface/alias": require("antd/es/theme/interface/alias"),
  "antd/es/theme/interface/components": require("antd/es/theme/interface/components"),
  "antd/es/theme/interface": require("antd/es/theme/interface"),
  "antd/es/theme/interface/maps/colors": require("antd/es/theme/interface/maps/colors"),
  "antd/es/theme/interface/maps/font": require("antd/es/theme/interface/maps/font"),
  "antd/es/theme/interface/maps": require("antd/es/theme/interface/maps"),
  "antd/es/theme/interface/maps/size": require("antd/es/theme/interface/maps/size"),
  "antd/es/theme/interface/maps/style": require("antd/es/theme/interface/maps/style"),
  "antd/es/theme/interface/presetColors": require("antd/es/theme/interface/presetColors"),
  "antd/es/theme/interface/seeds": require("antd/es/theme/interface/seeds"),
  "antd/es/theme/internal": require("antd/es/theme/internal"),
  "antd/es/theme/themes/ColorMap": require("antd/es/theme/themes/ColorMap"),
  "antd/es/theme/themes/compact/genCompactSizeMapToken": require("antd/es/theme/themes/compact/genCompactSizeMapToken"),
  "antd/es/theme/themes/compact": require("antd/es/theme/themes/compact"),
  "antd/es/theme/themes/dark/colorAlgorithm": require("antd/es/theme/themes/dark/colorAlgorithm"),
  "antd/es/theme/themes/dark/colors": require("antd/es/theme/themes/dark/colors"),
  "antd/es/theme/themes/dark": require("antd/es/theme/themes/dark"),
  "antd/es/theme/themes/default/colorAlgorithm": require("antd/es/theme/themes/default/colorAlgorithm"),
  "antd/es/theme/themes/default/colors": require("antd/es/theme/themes/default/colors"),
  "antd/es/theme/themes/default": require("antd/es/theme/themes/default"),
  "antd/es/theme/themes/seed": require("antd/es/theme/themes/seed"),
  "antd/es/theme/themes/shared/genColorMapToken": require("antd/es/theme/themes/shared/genColorMapToken"),
  "antd/es/theme/themes/shared/genCommonMapToken": require("antd/es/theme/themes/shared/genCommonMapToken"),
  "antd/es/theme/themes/shared/genControlHeight": require("antd/es/theme/themes/shared/genControlHeight"),
  "antd/es/theme/themes/shared/genFontMapToken": require("antd/es/theme/themes/shared/genFontMapToken"),
  "antd/es/theme/themes/shared/genFontSizes": require("antd/es/theme/themes/shared/genFontSizes"),
  "antd/es/theme/themes/shared/genRadius": require("antd/es/theme/themes/shared/genRadius"),
  "antd/es/theme/themes/shared/genSizeMapToken": require("antd/es/theme/themes/shared/genSizeMapToken"),
  "antd/es/theme/useToken": require("antd/es/theme/useToken"),
  "antd/es/theme/util/alias": require("antd/es/theme/util/alias"),
  "antd/es/theme/util/calc/CSSCalculator": require("antd/es/theme/util/calc/CSSCalculator"),
  "antd/es/theme/util/calc/NumCalculator": require("antd/es/theme/util/calc/NumCalculator"),
  "antd/es/theme/util/calc/calculator": require("antd/es/theme/util/calc/calculator"),
  "antd/es/theme/util/calc": require("antd/es/theme/util/calc"),
  "antd/es/theme/util/genComponentStyleHook": require("antd/es/theme/util/genComponentStyleHook"),
  "antd/es/theme/util/genPresetColor": require("antd/es/theme/util/genPresetColor"),
  "antd/es/theme/util/getAlphaColor": require("antd/es/theme/util/getAlphaColor"),
  "antd/es/theme/util/maxmin": require("antd/es/theme/util/maxmin"),
  "antd/es/theme/util/statistic": require("antd/es/theme/util/statistic"),
  "antd/es/theme/util/useResetIconStyle": require("antd/es/theme/util/useResetIconStyle"),
  "antd/es/time-picker": require("antd/es/time-picker"),
  "antd/es/time-picker/locale/ar_EG": require("antd/es/time-picker/locale/ar_EG"),
  "antd/es/time-picker/locale/az_AZ": require("antd/es/time-picker/locale/az_AZ"),
  "antd/es/time-picker/locale/bg_BG": require("antd/es/time-picker/locale/bg_BG"),
  "antd/es/time-picker/locale/bn_BD": require("antd/es/time-picker/locale/bn_BD"),
  "antd/es/time-picker/locale/by_BY": require("antd/es/time-picker/locale/by_BY"),
  "antd/es/time-picker/locale/ca_ES": require("antd/es/time-picker/locale/ca_ES"),
  "antd/es/time-picker/locale/cs_CZ": require("antd/es/time-picker/locale/cs_CZ"),
  "antd/es/time-picker/locale/da_DK": require("antd/es/time-picker/locale/da_DK"),
  "antd/es/time-picker/locale/de_DE": require("antd/es/time-picker/locale/de_DE"),
  "antd/es/time-picker/locale/el_GR": require("antd/es/time-picker/locale/el_GR"),
  "antd/es/time-picker/locale/en_GB": require("antd/es/time-picker/locale/en_GB"),
  "antd/es/time-picker/locale/en_US": require("antd/es/time-picker/locale/en_US"),
  "antd/es/time-picker/locale/es_ES": require("antd/es/time-picker/locale/es_ES"),
  "antd/es/time-picker/locale/et_EE": require("antd/es/time-picker/locale/et_EE"),
  "antd/es/time-picker/locale/eu_ES": require("antd/es/time-picker/locale/eu_ES"),
  "antd/es/time-picker/locale/fa_IR": require("antd/es/time-picker/locale/fa_IR"),
  "antd/es/time-picker/locale/fi_FI": require("antd/es/time-picker/locale/fi_FI"),
  "antd/es/time-picker/locale/fr_BE": require("antd/es/time-picker/locale/fr_BE"),
  "antd/es/time-picker/locale/fr_CA": require("antd/es/time-picker/locale/fr_CA"),
  "antd/es/time-picker/locale/fr_FR": require("antd/es/time-picker/locale/fr_FR"),
  "antd/es/time-picker/locale/ga_IE": require("antd/es/time-picker/locale/ga_IE"),
  "antd/es/time-picker/locale/gl_ES": require("antd/es/time-picker/locale/gl_ES"),
  "antd/es/time-picker/locale/he_IL": require("antd/es/time-picker/locale/he_IL"),
  "antd/es/time-picker/locale/hi_IN": require("antd/es/time-picker/locale/hi_IN"),
  "antd/es/time-picker/locale/hr_HR": require("antd/es/time-picker/locale/hr_HR"),
  "antd/es/time-picker/locale/hu_HU": require("antd/es/time-picker/locale/hu_HU"),
  "antd/es/time-picker/locale/id_ID": require("antd/es/time-picker/locale/id_ID"),
  "antd/es/time-picker/locale/is_IS": require("antd/es/time-picker/locale/is_IS"),
  "antd/es/time-picker/locale/it_IT": require("antd/es/time-picker/locale/it_IT"),
  "antd/es/time-picker/locale/ja_JP": require("antd/es/time-picker/locale/ja_JP"),
  "antd/es/time-picker/locale/ka_GE": require("antd/es/time-picker/locale/ka_GE"),
  "antd/es/time-picker/locale/kk_KZ": require("antd/es/time-picker/locale/kk_KZ"),
  "antd/es/time-picker/locale/km_KH": require("antd/es/time-picker/locale/km_KH"),
  "antd/es/time-picker/locale/kmr_IQ": require("antd/es/time-picker/locale/kmr_IQ"),
  "antd/es/time-picker/locale/kn_IN": require("antd/es/time-picker/locale/kn_IN"),
  "antd/es/time-picker/locale/ko_KR": require("antd/es/time-picker/locale/ko_KR"),
  "antd/es/time-picker/locale/lt_LT": require("antd/es/time-picker/locale/lt_LT"),
  "antd/es/time-picker/locale/lv_LV": require("antd/es/time-picker/locale/lv_LV"),
  "antd/es/time-picker/locale/mk_MK": require("antd/es/time-picker/locale/mk_MK"),
  "antd/es/time-picker/locale/ml_IN": require("antd/es/time-picker/locale/ml_IN"),
  "antd/es/time-picker/locale/mn_MN": require("antd/es/time-picker/locale/mn_MN"),
  "antd/es/time-picker/locale/ms_MY": require("antd/es/time-picker/locale/ms_MY"),
  "antd/es/time-picker/locale/my_MM": require("antd/es/time-picker/locale/my_MM"),
  "antd/es/time-picker/locale/nb_NO": require("antd/es/time-picker/locale/nb_NO"),
  "antd/es/time-picker/locale/nl_BE": require("antd/es/time-picker/locale/nl_BE"),
  "antd/es/time-picker/locale/nl_NL": require("antd/es/time-picker/locale/nl_NL"),
  "antd/es/time-picker/locale/pl_PL": require("antd/es/time-picker/locale/pl_PL"),
  "antd/es/time-picker/locale/pt_BR": require("antd/es/time-picker/locale/pt_BR"),
  "antd/es/time-picker/locale/pt_PT": require("antd/es/time-picker/locale/pt_PT"),
  "antd/es/time-picker/locale/ro_RO": require("antd/es/time-picker/locale/ro_RO"),
  "antd/es/time-picker/locale/ru_RU": require("antd/es/time-picker/locale/ru_RU"),
  "antd/es/time-picker/locale/si_LK": require("antd/es/time-picker/locale/si_LK"),
  "antd/es/time-picker/locale/sk_SK": require("antd/es/time-picker/locale/sk_SK"),
  "antd/es/time-picker/locale/sl_SI": require("antd/es/time-picker/locale/sl_SI"),
  "antd/es/time-picker/locale/sr_RS": require("antd/es/time-picker/locale/sr_RS"),
  "antd/es/time-picker/locale/sv_SE": require("antd/es/time-picker/locale/sv_SE"),
  "antd/es/time-picker/locale/ta_IN": require("antd/es/time-picker/locale/ta_IN"),
  "antd/es/time-picker/locale/th_TH": require("antd/es/time-picker/locale/th_TH"),
  "antd/es/time-picker/locale/tk_TK": require("antd/es/time-picker/locale/tk_TK"),
  "antd/es/time-picker/locale/tr_TR": require("antd/es/time-picker/locale/tr_TR"),
  "antd/es/time-picker/locale/uk_UA": require("antd/es/time-picker/locale/uk_UA"),
  "antd/es/time-picker/locale/ur_PK": require("antd/es/time-picker/locale/ur_PK"),
  "antd/es/time-picker/locale/uz_UZ": require("antd/es/time-picker/locale/uz_UZ"),
  "antd/es/time-picker/locale/vi_VN": require("antd/es/time-picker/locale/vi_VN"),
  "antd/es/time-picker/locale/zh_CN": require("antd/es/time-picker/locale/zh_CN"),
  "antd/es/time-picker/locale/zh_TW": require("antd/es/time-picker/locale/zh_TW"),
  "antd/es/timeline/Timeline": require("antd/es/timeline/Timeline"),
  "antd/es/timeline/TimelineItem": require("antd/es/timeline/TimelineItem"),
  "antd/es/timeline/TimelineItemList": require("antd/es/timeline/TimelineItemList"),
  "antd/es/timeline": require("antd/es/timeline"),
  "antd/es/timeline/style": require("antd/es/timeline/style"),
  "antd/es/timeline/useItems": require("antd/es/timeline/useItems"),
  "antd/es/tooltip/PurePanel": require("antd/es/tooltip/PurePanel"),
  "antd/es/tooltip": require("antd/es/tooltip"),
  "antd/es/tooltip/style": require("antd/es/tooltip/style"),
  "antd/es/tooltip/util": require("antd/es/tooltip/util"),
  "antd/es/tour/PurePanel": require("antd/es/tour/PurePanel"),
  "antd/es/tour": require("antd/es/tour"),
  "antd/es/tour/interface": require("antd/es/tour/interface"),
  "antd/es/tour/panelRender": require("antd/es/tour/panelRender"),
  "antd/es/tour/style": require("antd/es/tour/style"),
  "antd/es/transfer/ListBody": require("antd/es/transfer/ListBody"),
  "antd/es/transfer/ListItem": require("antd/es/transfer/ListItem"),
  "antd/es/transfer/hooks/useData": require("antd/es/transfer/hooks/useData"),
  "antd/es/transfer/hooks/useSelection": require("antd/es/transfer/hooks/useSelection"),
  "antd/es/transfer": require("antd/es/transfer"),
  "antd/es/transfer/interface": require("antd/es/transfer/interface"),
  "antd/es/transfer/list": require("antd/es/transfer/list"),
  "antd/es/transfer/operation": require("antd/es/transfer/operation"),
  "antd/es/transfer/search": require("antd/es/transfer/search"),
  "antd/es/transfer/style": require("antd/es/transfer/style"),
  "antd/es/tree/DirectoryTree": require("antd/es/tree/DirectoryTree"),
  "antd/es/tree/Tree": require("antd/es/tree/Tree"),
  "antd/es/tree": require("antd/es/tree"),
  "antd/es/tree/style": require("antd/es/tree/style"),
  "antd/es/tree/utils/dictUtil": require("antd/es/tree/utils/dictUtil"),
  "antd/es/tree/utils/dropIndicator": require("antd/es/tree/utils/dropIndicator"),
  "antd/es/tree/utils/iconUtil": require("antd/es/tree/utils/iconUtil"),
  "antd/es/tree-select": require("antd/es/tree-select"),
  "antd/es/tree-select/style": require("antd/es/tree-select/style"),
  "antd/es/typography/Base/CopyBtn": require("antd/es/typography/Base/CopyBtn"),
  "antd/es/typography/Base/Ellipsis": require("antd/es/typography/Base/Ellipsis"),
  "antd/es/typography/Base/EllipsisTooltip": require("antd/es/typography/Base/EllipsisTooltip"),
  "antd/es/typography/Base": require("antd/es/typography/Base"),
  "antd/es/typography/Base/util": require("antd/es/typography/Base/util"),
  "antd/es/typography/Editable": require("antd/es/typography/Editable"),
  "antd/es/typography/Link": require("antd/es/typography/Link"),
  "antd/es/typography/Paragraph": require("antd/es/typography/Paragraph"),
  "antd/es/typography/Text": require("antd/es/typography/Text"),
  "antd/es/typography/Title": require("antd/es/typography/Title"),
  "antd/es/typography/Typography": require("antd/es/typography/Typography"),
  "antd/es/typography/hooks/useCopyClick": require("antd/es/typography/hooks/useCopyClick"),
  "antd/es/typography/hooks/useMergedConfig": require("antd/es/typography/hooks/useMergedConfig"),
  "antd/es/typography/hooks/usePrevious": require("antd/es/typography/hooks/usePrevious"),
  "antd/es/typography/hooks/useUpdatedEffect": require("antd/es/typography/hooks/useUpdatedEffect"),
  "antd/es/typography": require("antd/es/typography"),
  "antd/es/typography/style": require("antd/es/typography/style"),
  "antd/es/typography/style/mixins": require("antd/es/typography/style/mixins"),
  "antd/es/upload/Dragger": require("antd/es/upload/Dragger"),
  "antd/es/upload/Upload": require("antd/es/upload/Upload"),
  "antd/es/upload/UploadList/ListItem": require("antd/es/upload/UploadList/ListItem"),
  "antd/es/upload/UploadList": require("antd/es/upload/UploadList"),
  "antd/es/upload": require("antd/es/upload"),
  "antd/es/upload/interface": require("antd/es/upload/interface"),
  "antd/es/upload/style/dragger": require("antd/es/upload/style/dragger"),
  "antd/es/upload/style": require("antd/es/upload/style"),
  "antd/es/upload/style/list": require("antd/es/upload/style/list"),
  "antd/es/upload/style/motion": require("antd/es/upload/style/motion"),
  "antd/es/upload/style/picture": require("antd/es/upload/style/picture"),
  "antd/es/upload/style/rtl": require("antd/es/upload/style/rtl"),
  "antd/es/upload/utils": require("antd/es/upload/utils"),
  "antd/es/version": require("antd/es/version"),
  "antd/es/version/version": require("antd/es/version/version"),
  "antd/es/watermark/context": require("antd/es/watermark/context"),
  "antd/es/watermark": require("antd/es/watermark"),
  "antd/es/watermark/useClips": require("antd/es/watermark/useClips"),
  "antd/es/watermark/useRafDebounce": require("antd/es/watermark/useRafDebounce"),
  "antd/es/watermark/useWatermark": require("antd/es/watermark/useWatermark"),
  "antd/es/watermark/utils": require("antd/es/watermark/utils")
};

// ../utils/package-imports/dndKitCoreImports.js
var dndKitCoreImports_exports = {};
__export(dndKitCoreImports_exports, {
  default: () => dndKitCoreImports_default
});
var dndKitCoreImports_default = {
  "@dnd-kit/core": require("@dnd-kit/core"),
  "@dnd-kit/core/dist/core.cjs.development": require("@dnd-kit/core/dist/core.cjs.development"),
  "@dnd-kit/core/dist/core.cjs.production.min": require("@dnd-kit/core/dist/core.cjs.production.min"),
  "@dnd-kit/core/dist/core.esm": require("@dnd-kit/core/dist/core.esm"),
  "@dnd-kit/core/dist": require("@dnd-kit/core/dist")
};

// ../utils/package-imports/dndKitSortableImports.js
var dndKitSortableImports_exports = {};
__export(dndKitSortableImports_exports, {
  default: () => dndKitSortableImports_default
});
var dndKitSortableImports_default = {
  "@dnd-kit/sortable": require("@dnd-kit/sortable"),
  "@dnd-kit/sortable/dist": require("@dnd-kit/sortable/dist"),
  "@dnd-kit/sortable/dist/sortable.cjs.development": require("@dnd-kit/sortable/dist/sortable.cjs.development"),
  "@dnd-kit/sortable/dist/sortable.cjs.production.min": require("@dnd-kit/sortable/dist/sortable.cjs.production.min"),
  "@dnd-kit/sortable/dist/sortable.esm": require("@dnd-kit/sortable/dist/sortable.esm")
};

// ../utils/package-imports/dndKitUtilitiesImports.js
var dndKitUtilitiesImports_exports = {};
__export(dndKitUtilitiesImports_exports, {
  default: () => dndKitUtilitiesImports_default
});
var dndKitUtilitiesImports_default = {
  "@dnd-kit/utilities": require("@dnd-kit/utilities"),
  "@dnd-kit/utilities/dist": require("@dnd-kit/utilities/dist"),
  "@dnd-kit/utilities/dist/utilities.cjs.development": require("@dnd-kit/utilities/dist/utilities.cjs.development"),
  "@dnd-kit/utilities/dist/utilities.cjs.production.min": require("@dnd-kit/utilities/dist/utilities.cjs.production.min"),
  "@dnd-kit/utilities/dist/utilities.esm": require("@dnd-kit/utilities/dist/utilities.esm")
};

// ../utils/loadRemoteComponent.js
var import_axios2 = __toESM(require("axios"));
var defaultRequires = function(name) {
  if (name === "react") {
    return import_react10.default;
  } else if (name === "utils") {
    return utils_exports;
  } else if (name === "@ant-design/cssinjs") {
    return CssInJs;
  } else if (name === "react/jsx-runtime") {
    return jsxRuntime;
  }
  for (let packageName of Object.keys(package_imports_exports)) {
    if (package_imports_exports[packageName].default.hasOwnProperty(name)) {
      return package_imports_exports[packageName].default[name];
    }
  }
  throw new Error("Could not require '" + name + "'. The 'requires' function was not provided.");
};
var loadFile = async (baseUrl, componentName, fileName, prefixBucketPath) => {
  try {
    const response = await import_axios2.default.post(`${baseUrl}/api/components`, {
      componentName,
      prefixBucketPath,
      fileName
    });
    return response.data;
  } catch (error) {
    return "";
  }
};
var loadCss = async (baseUrl, componentName, prefixBucketPath) => {
  return loadFile(baseUrl, componentName, "dist.csr.index.css", prefixBucketPath);
};
var loadComponent = async (baseUrl, componentName, prefixBucketPath) => {
  try {
    const componentCode = await loadFile(baseUrl, componentName, "dist.csr.index.js", prefixBucketPath);
    if (!componentCode) {
      console.error("Empty component!");
      return {};
    }
    let _requires = defaultRequires;
    let exports2 = {};
    let module2 = { exports: exports2 };
    const func = new Function("require", "module", "exports", componentCode);
    func(_requires, module2, exports2);
    return module2.exports.default;
  } catch (error) {
    console.error("Error loading component:", error);
    return {};
  }
};
var isComponentCached = (componentName) => isCache(`components.${componentName}`);
var getComponentInCache = (componentName) => getCache(`components.${componentName}`);
var _loadComponentAndCss = async ({ baseUrl = "", componentName, prefixBucketPath = "", forceUpdate = false }) => {
  if (!componentName) {
    return {
      kind: "error",
      message: "Invalid props"
    };
  }
  if (isComponentCached(componentName) && forceUpdate === false) {
    return {
      kind: "success"
    };
  }
  try {
    const result = await Promise.all([
      loadComponent(baseUrl, componentName, prefixBucketPath),
      loadCss(baseUrl, componentName, prefixBucketPath)
    ]);
    const Comp = safeGet(result[0], "default", null);
    if (Comp) {
      setCache(`components.${componentName}`, Comp);
      injectStyle(result[1], ".unified-react-container");
      return {
        kind: "success",
        message: ""
      };
    } else {
      return {
        kind: "error",
        message: `Cannot fetch ${componentName}`
      };
    }
  } catch (error) {
    return {
      kind: "error",
      message: `Error fetching component ${componentName}: ${error}`
    };
  }
};
var cacheRequestPromise = {};
var loadComponentAndCss = (params) => {
  const key = JSON.stringify(params);
  let requestPromise = cacheRequestPromise[key];
  if (!requestPromise || params.forceUpdate) {
    requestPromise = (async () => {
      const response = await _loadComponentAndCss(params);
      if (response.kind !== "success") {
        cacheRequestPromise[key] = null;
      }
      return response;
    })();
    cacheRequestPromise[key] = requestPromise;
  }
  return requestPromise;
};

// ../utils/custom.js
var createCustomValidate = (customRule) => {
  return () => ({
    validator(_) {
      const trimmedRule = customRule ? customRule.trim() : "";
      if (trimmedRule === "!") {
        return Promise.resolve();
      }
      return trimmedRule ? Promise.reject(new Error(trimmedRule)) : Promise.resolve();
    }
  });
};
var createResolveValidate = () => {
  return () => ({
    validator(_) {
      return Promise.resolve();
    }
  });
};
var evaluateCustomRules = (customRules) => {
  return customRules.map((customRule) => {
    const trimmedRule = customRule ? customRule.trim() : "";
    return trimmedRule === "";
  });
};
function safeEval(logic) {
  try {
    const func = new Function(`return ${logic};`);
    return func();
  } catch (e) {
    console.error("Error evaluating logic:", e);
    return false;
  }
}
function evaluateLogic(logic, rulesArray) {
  logic = logic.replace(/\bAND\b/g, "&&").replace(/\bOR\b/g, "||").replace(/\bNOT\b/g, "!");
  logic = logic.replace(/[A-Z]+/g, (match) => {
    const index = match.charCodeAt(0) - "A".charCodeAt(0);
    return rulesArray[index] !== void 0 ? rulesArray[index] : false;
  });
  return safeEval(logic);
}
function processCustomRules(customRules, rules, logic) {
  const logicResult = evaluateLogic(logic, evaluateCustomRules(customRules));
  if (logicResult) {
    rules.push(...customRules.map(createResolveValidate()));
  } else {
    rules.push(...customRules.map(createCustomValidate));
  }
  return rules;
}

// ../utils/hooks/useResizeObserver.js
var import_react11 = require("react");
var useResizeObserver = (ref, callback, dependencies = []) => {
  (0, import_react11.useEffect)(() => {
    const resizeObserver = new ResizeObserver(callback);
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [callback, ...dependencies || []]);
};

// ../utils/hooks/useMount.js
var import_react12 = require("react");
var useMount = () => {
  const [isMount, setIsMount] = (0, import_react12.useState)(false);
  (0, import_react12.useEffect)(() => {
    setIsMount(true);
  }, []);
  return isMount;
};

// ../utils/hooks/useInterval.js
var import_react13 = require("react");
var useInterval = (callback, delay) => {
  const savedCallback = (0, import_react13.useRef)();
  (0, import_react13.useEffect)(() => {
    savedCallback.current = callback;
  }, [callback]);
  (0, import_react13.useEffect)(() => {
    if (delay !== null) {
      const tick = () => savedCallback.current();
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ACTION_TYPES,
  AppContext,
  DefaultFailback,
  ErrorDrawer,
  RenderHiddenDiv,
  assignRef,
  assignRefToProps,
  base64ToFile,
  components,
  convertToKebabCase,
  dateUtils,
  downloadFile,
  fileToBase64,
  findChildren,
  flattenChildren,
  generateObject,
  getAtPath,
  getCache,
  getDescriptionTooltip,
  getElementById,
  getFileName,
  getTokenFromUrl,
  getUser,
  handleApiResponse,
  handleCallApi,
  hash256,
  initAuth0Sdk,
  initRootElement,
  injectParseFormulaIntoRules,
  injectStyle,
  isAuthenticated,
  isCache,
  isExpression,
  isNilOrEmpty,
  joinFieldPaths,
  jsonParseSafe,
  jsonStringifySafe,
  loadRemoteComponent,
  loadStyles,
  parseData,
  processCustomRules,
  safeGet,
  safeGetBoolean,
  safeGetFunction,
  safeGetString,
  setAtPath,
  setCache,
  storage,
  stringUtils,
  switchRefToForward,
  transformData,
  tryParseExpression,
  tryParseFormulaToArray,
  tryParseFormulaToInteger,
  tryParseFormulaToNumber,
  tryParseFormulaToString,
  tryParseFormulaToType,
  useAppContext,
  useInterval,
  useLocalStorage,
  useMount,
  useResizeObserver,
  useResponsiveStyle,
  verifyToken,
  withErrorBoundary
});
