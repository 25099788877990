export default {
        application: require('./components/application/dist.index.js').default,
layout: require('./components/layout/dist.index.js').default,
spin: require('./components/spin/dist.index.js').default,
sidebarCollapsed: require('./components/sidebarCollapsed/dist.index.js').default,
flex: require('./components/flex/dist.index.js').default,
image: require('./components/image/dist.index.js').default,
button: require('./components/button/dist.index.js').default,
icon: require('./components/icon/dist.index.js').default,
text: require('./components/text/dist.index.js').default,
switch: require('./components/switch/dist.index.js').default,
avatar: require('./components/avatar/dist.index.js').default,
breadcrumb: require('./components/breadcrumb/dist.index.js').default,
form: require('./components/form/dist.index.js').default,
select: require('./components/select/dist.index.js').default,
card: require('./components/card/dist.index.js').default,
progress: require('./components/progress/dist.index.js').default,
table: require('./components/table/dist.index.js').default,
chart: require('./components/chart/dist.index.js').default,
modal: require('./components/modal/dist.index.js').default,
codeEditor: require('./components/codeEditor/dist.index.js').default,
tabs: require('./components/tabs/dist.index.js').default
      }